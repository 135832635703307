import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import sagas from '../sagas';
import reducers from '../reducers';

export default function (initialState = {}) {
  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

  const enchancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(reducers, initialState, enchancer);
  sagaMiddleware.run(sagas);

  return { history, store };
}
