import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../utils/request';
export function* fetchmenutree ({ id,callback, ...params }) {
  try{
    const uri = '/api/v1/pub/current/menutree';
    const data = yield call(request, uri, {
      method: 'GET'
    });
    // console.log(data)
    if(callback) {callback(data)};
    const formatedata = data.Data.list.map((item)=>{
      item.path = item.router
      if(item.children&&item.children.length!==0){
        item.children.map((i)=>{
          i.path = i.router;
          return i;
        })
      }
      return item;
    })
    yield put({
      type: 'global/success',
      payload: formatedata
    });
  }catch(err){
    console.log(err)
  }
}



// export function* topmenue ({ data, ...params }) {
//   console.log('ashdjkhagsdjagsjdh')
//   try{
     
//     yield put({
//       type: 'global/topmenue',
//       payload: data
//     });
//   }catch(err){
//     console.log(err)
//   }
// }

export function* watchFetchmenutree () {
  yield takeLatest('MENU_TREE', fetchmenutree);
}

// export function* watchTopmenue () {
//   yield takeLatest('GLOBAL_TOPMENUE', topmenue);
// }





export default [
    watchFetchmenutree
    // watchTopmenue
];
