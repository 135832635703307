import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../utils/request';
import con from '../utils/config'

const {m1} = con

// export function* fetch ({callback}) {
//     try {
//         const uri = m1+'/software';
//         const data = yield call(request, uri, {
//             method: 'GET',
//         });
//         if (callback){callback(data.Data)}
    
//         yield put({
//             type: 'software/list',
//             payload: data['Data']
//         });
  
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   export function* ableversion ({softwareName,callback}) {
//     try {
//         const uri = m1+'/software/'+softwareName;
//         const data = yield call(request, uri, {
//             method: 'GET',
//         });
//         if (callback){callback(data.Data)}
//         yield put({
//             type: 'software/expandlist',
//             payload: data['Data']
//         });
//         console.log(data)
  
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   export function* deletesoftware ({softwareName,callback}) {
//     try {
//         const uri = m1+'/software/'+softwareName
//         const data = yield call(request, uri, {
//             method: 'DELETE',
//         });
//         if (callback){callback(data.Data)}
//         console.log(data)
  
//     } catch (error) {
//       console.log(error);
//     }
//   }


  /**
   * 软件管理模块
   * 查询软件管理列表
   * 字段：page、size、fuzzy模糊查询
   */

  export function* fetchSoftwarelist ({callback,page,size,fuzzy}) {
    try {
      const uri = m1+'/udrive/softwares';
      const data = yield call(request, uri, {
          method: 'POST',
          body:{
            page,
            size,
            fuzzy
          }
      });
      console.log(data['Data'].softwares)
      if (callback){callback(data.Data)}
      yield put({
          type: 'software/list',
          payload: data['Data']
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 功能描述：查询软件版本列表
   * @param {*gid page size}  
   */

  export function* fetchSoftwareVersion ({callback,gid,page,size}) {
    try {
      const uri = m1+`/udrive/software/versions?gid=${gid}&page=${page}&size=${size}`
      //const uri = '/udrive/software/versions?gid='+gid+'&page='+page+'&size='+size;
      const data = yield call(request, uri, {
          method: 'GET'
      });
      console.log(data)
    //   yield put({
    //     type: 'software/expandlist',
    //     payload: data['Data']
    // });
      if (callback){callback(data.Data)}
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 功能描述：查询软件配置列表
   * @param {*gid page size}  
   */

  export function* fetchSoftwareConfig ({callback,gid,page,size}) {
    try {
      const uri = m1+`/udrive/software/confs?gid=${gid}&page=${page}&size=${size}`
      //const uri = '/udrive/software/versions?gid='+gid+'&page='+page+'&size='+size;
      const data = yield call(request, uri, {
          method: 'GET'
      });
      if (callback){callback(data.Data)}
    } catch (error) {
      console.log(error);
    }
  }


  /**
   * 功能描述：查询单个配置
   *  @param {*id}  
   */

  export function* searchSoftwareOnlyConfig ({callback,id}) {
    try {
      const uri = m1+`/udrive/software/conf?id=${id}`
      //const uri = '/udrive/software/versions?gid='+gid+'&page='+page+'&size='+size;
      const data = yield call(request, uri, {
          method: 'GET'
      });
      if (callback){callback(data.Data)}
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 功能描述：添加单个配置
   * gid,name,file
   */
  export function* addOnlyConfig ({callback,payload}) {
    try {
      const uri = m1+'/udrive/software/conf';
      const data = yield call(request, uri, {
          method: 'POST',
          body:payload
      });
      if (callback){callback(data.Data)}
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 功能描述：删除单个配置
   * id
   */
  export function* deleteOnlyConfig ({callback,id}) {
    try {
      const uri = m1+'/udrive/software/conf';
      const data = yield call(request, uri, {
          method: 'DELETE',
          body:{
            id
          }
      });
      if (callback){callback(data.Data)}
    } catch (error) {
      console.log(error);
    }
  }


/**
 * 功能描述：编辑单个配置
 * id reason  info
 */
  export function* editOnlyConfig ({callback,id,reason,info}) {
    try {
      const uri = m1+'/udrive/software/conf';
      const data = yield call(request, uri, {
          method: 'PUT',
          body:{
            id,
            reason,
            info
          }
      });
      if (callback){callback(data)}
    } catch (error) {
      console.log(error);
    }
  }

/**
 * 功能描述：软件安装至车辆
 * id cars
 */
export function* installSoftware ({callback,id,cars}) {
  try {
    const uri = m1+'/udrive/software/cars';
    const data = yield call(request, uri, {
        method: 'POST',
        body:{
          id,
          cars
        }
    });
    if (callback){callback(data.Data)}
  } catch (error) {
    console.log(error);
  }
}

/**
 * 功能描述：查询配置历史记录列表
 * id page size
 */

export function* fetchSoftwareConfigHistory ({callback,id,page,size}) {
  try {
    const uri = m1+`/udrive/software/conf/historys?id=${id}&page=${page}&size=${size}`
    const data = yield call(request, uri, {
        method: 'GET'
    });
    if (callback){callback(data.Data)}
  } catch (error) {
    console.log(error);
  }
}

/**
 * 功能描述：查询单个配置历史记录列表
 * id page size
 */
export function* fetchOnlySoftwareConfigHistory ({callback,id}) {
  try {
    const uri = m1+`/udrive/software/conf/historys?id=${id}`
    const data = yield call(request, uri, {
        method: 'GET'
    });
    if (callback){callback(data.Data)}
  } catch (error) {
    console.log(error);
  }
}


  export function* watchSoftwarelist () {
    yield takeLatest('SOFTWARE_LIST', fetchSoftwarelist);
  }
  
  export function* watchSoftwareversionlist () {
    yield takeLatest('SOFTWARE_VERSIONLIST', fetchSoftwareVersion);
  }

  export function* watchSoftwareconfig () {
    yield takeLatest('SOFTWARE_CONFIG', fetchSoftwareConfig);
  }

  export function* watchSoftwareOnlyconfig () {
    yield takeLatest('SOFTWARE_ONLYCONFIG', searchSoftwareOnlyConfig);
  }


  export function* watchSoftAddconfig () {
    yield takeLatest('SOFTWARE_ADDCONFIG', addOnlyConfig);
  }

  export function* watchSoftDeleteconfig () {
    yield takeLatest('SOFTWARE_DELETECONFIG', deleteOnlyConfig);
  }

  export function* watchSoftEditconfig () {
    yield takeLatest('SOFTWARE_EDITECONFIG', editOnlyConfig);
  }

  export function* watchInstallSoftware () {
    yield takeLatest('SOFTWARE_INSTALL', installSoftware);
  }

  export function* watchSoftwareConfigHistorylist () {
    yield takeLatest('SOFTWARE_CONFIGHISTORYLIST', fetchSoftwareConfigHistory);
  }

  export function* watchSoftwareOnlyConfigHistorylist () {
    yield takeLatest('SOFTWARE_CONFIGONLYHISTORYLIST', fetchOnlySoftwareConfigHistory);
  }


  // export function* watchAbleversion () {
  //   yield takeLatest('ABLE_VERSION', ableversion);
  // }
  

  // export function* watchDeleteSoftware () {
  //   yield takeLatest('SOFTWARE_DELETE', deletesoftware);
  // }
  


  export default [
    watchSoftwarelist,
    watchSoftwareversionlist,
    watchSoftwareconfig,
    watchSoftwareOnlyconfig,
    watchSoftAddconfig,
    watchSoftDeleteconfig,
    watchSoftEditconfig,
    watchInstallSoftware,
    watchSoftwareConfigHistorylist,
    watchSoftwareOnlyConfigHistorylist






    // watchAbleversion,
    // watchDeleteSoftware
  ];
  