import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
// import registerServiceWorker from './registerServiceWorker';
import router from './router';
import './index.less';

const { history, store } = configureStore();
const ROOT_CONTAINER = document.getElementById('root');
if (!ROOT_CONTAINER) {
  throw new Error('当前页面不存在 <div id="root"></div> 节点.');
}
 
ReactDOM.render(
  // <IntlProvider 
  //   locale={'cn'} 
  //   messages={zh_CN}
  // >
    <Provider store={store}>
      {router(history)}
    </Provider>
  // </IntlProvider>
  ,
  ROOT_CONTAINER
);
// registerServiceWorker();
