import { takeLatest, call, put, select } from 'redux-saga/effects';
import request from '../utils/request';
import con from '../utils/config'

const {m1} = con
export function* fetch ({ id,callback, ...param }) {
 try{
  let uri = m1+ '/areas';
  if (typeof id !== 'undefined') {
    uri += `/${id}`;
  }
  const data = yield call(request, uri, {
    method: 'GET'
  });
  if(callback) callback(data);
  yield put({
    type: 'area/success',
    payload: data['Data']
  });
 }catch(err){
   console.log(err)
 }
}
export function* searcharea ({ AreaName, callback}) {
  try{
    const uri =m1+ '/areas/?AreaName='+AreaName;
    const data = yield call(request, uri, {
      method: 'GET'
    });
    if(callback) callback(data.Data);
  }catch(err){
    console.log(err)
  }
 
}

export function* fetchLoopStaions({AreaName,callback}) {
  try{
    let uri = m1+'/areas/loop_stations/?AreaName='+AreaName;
    const data = yield call(request, uri, {
      method:'GET'
    });
    if(callback) callback(data);
    yield put({
      type: 'area/loopstations',
      payload:data['Data']
    })
  }catch(err){
    console.log(err)
  }
}

export function* add ({ GPS,AreaName,OwnBy,callback}) {
  try {
    const uri = m1+ '/areas';
    const data = yield call(request, uri, {
      method: 'POST',
      body: {
        GPS,
        AreaName,
        OwnBy
      }
    });
    if(callback) callback(data);
    if (data.Status !== 0) {
      yield put({ type: 'area/add_success' });
    }else{
      yield put({
        type: 'area/add_failed',
        payload: '小车添加失败'
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deletearea({AreaName,callback}){
  try {
    const uri = m1+ '/areas/?AreaName='+ AreaName;
    const data = yield call(request, uri, {
      method: 'DELETE'
    });
    if(callback) callback(data);
    if(data){
      const district = yield select(state => state.area);
      const areas = district.data.filter(item => item.AreaName !== AreaName);
      yield put({
        type: 'area/success',
        payload: areas
      });
    }
  } catch (error) {
    console.log(error);
  }
}



export function* addstation ({Name,AreaName,Position,callback}) {
  try {
    const uri = m1+ '/area_station/?AreaName='+AreaName;
    const data = yield call(request, uri, {
      method: 'POST',
      body: {
        Name,
        // GPS
        Position
      }
    });
    if(callback) callback(data);
  } catch (error) {
    console.log(error);
  }

}

export function* deletestation ({StationName,AreaName,callback}) {
  try {
    const uri = m1+ '/area_station/?AreaName='+ AreaName +'&StationName=' + StationName;
    const data = yield call(request, uri, {
      method: 'DELETE'
    });
    if(callback) callback(JSON.parse(data));
    } catch (error) {
      console.log(error);
    }

  // yield put({
  //   type: 'area/success',
  //   payload: data['Data']
  // });
}

export function* carsall ({AreaName,callback}) {
  try {
    const uri = m1+ '/cars_state/?AreaName='+ AreaName;
    const data = yield call(request, uri, {
      method: 'GET'
    });
    if(callback) callback(data['States']);
    if(data.Status === 1){
       yield put({
        type: 'area/carstate',
        payload: data['States']
      });
    }

    } catch (error) {
      console.log(error);
    }


}



export function* config ({AreaName,URL,Origin,Rotate,Xscale,Yscale,LoopSwitch,LoopStations,callback}) {
  console.log(arguments)
  try {
    const uri = m1+ '/areas/?AreaName='+AreaName;
    const data = yield call(request, uri, {
      method: 'PATCH',
      body: {
       Config:{
        URL,
        Origin,
        Rotate,
        Xscale,
        Yscale,
        LoopSwitch,
        LoopStations
       }
      }
    });
    if(callback) callback(data);
  } catch (error) {
    console.log(error);
  }

}

export function* createroute({AreaName,Route,callback}){

try {
  const url = m1+ '/route'
  const data = yield call(request,url,{
    method:'POST',
    body:{
      // Id:"15345",
      AreaName,
      Route,
      // Version:'99.1.1'
    }
  });
  if(callback) callback(data);
  // if(data.Status === 1) {
  //   yield put({
  //     type:'area/create_success',
  //   })
  // } else {
  //   yield put({
  //     type:'area/create_failed',
  //     payload:'路径创建失败'
  //   })
  // }
} catch(error) {
  console.log(error);
}
}

export function* exchangestation({AreaName,LoopStations,callback}) {

  try{
    const url= m1+'/areas/loop_stations/?AreaName='+AreaName;
    const data= yield call(request,url,{
      method:'PATCH',
      body:{
        LoopStations
      }
    });
    if(callback) callback(data);
  } catch(error) {
    console.log(error);
  }
}

export function* deleteloopstation({AreaName,StationIndex,callback}){

  try{
    const url = m1+ '/areas/loop_stations/?AreaName='+AreaName+'&StationIndex='+StationIndex;
    const data = yield call(request,url,{
      method:'DELETE'
    });
    if(callback) callback(JSON.parse(data));
  }catch(error) {
    console.log(error)
  };
}

export function* addloopstation({AreaName,StationIndex,callback}){

  try{
    const url = m1+ '/areas/loop_stations/?AreaName='+AreaName+'&StationIndex='+StationIndex;
    const data = yield call(request,url,{
      method:'POST'
    });
    if(callback) callback(data);
  }catch(error) {console.log(error)};
}

export function* searchroute({Id,callback}){

  try {
    const url = m1+ '/route/'+Id;
    const data = yield call(request,url,{
      method:'GET'
    });
    if(callback) callback(data);
    yield put({
      type: 'area/route',
      payload:data['Data']
    })
  }catch(error) {console.log(error)};
}


export function* polishstation({AreaName,StationName,Station,callback}){

  try {
    const url = m1+ '/area_station/?AreaName='+AreaName+'&StationName='+StationName;
    const data = yield call(request,url,{
      method:'PATCH',
      body:{
          Name:Station.Name,
          Position:{
            X:Station.Position.X,
            Z:Station.Position.Z,
            Y:Station.Position.Y,
            YAW:Station.Position.YAW
          }
      }
    })
    if(callback) callback(data);

  }catch(error) {console.log(error)};
}




export function* searchstation({AreaName,StationName,callback}){

  try {
    const url = m1+ '/area_station/?AreaName='+AreaName+'&StationName='+StationName;
    const data = yield call(request,url,{
      method:'GET',
    })
    if(callback) callback(data);

  }catch(error) {console.log(error)};
}



export function* addreport({area_name,reportby,reason,lon,lat,callback}){
  console.log(area_name,reportby,reason,lon,lat)
  try {
    const url = m1+ '/roadblocks';
    const data = yield call(request,url,{
      method:'POST',
      body:{
        area_name,
        reportby,
        reason,
        lon,
        lat
      }
    })
    if(data){
      if(callback) callback(data);
    }

  }catch(error) {console.log(error)};
}

export function* reportlist({area_name,callback}){

  try {
    const url = m1+ '/roadblocks/?area_name='+area_name;
    const data = yield call(request,url,{
      method:'GET'
    })
    if(callback) callback(data);
    if(data){
      yield put({
        type: 'area/report',
        payload:data['Data']
      })
    }
  }catch(error) {console.log(error)};
}


export function* deletereport({area_name,id,callback}){

  try {
    const url = m1+ '/roadblocks/?area_name='+area_name+'&id='+id;
    const data = yield call(request,url,{
      method:'DELETE'
    })
    console.log(data)
    if(callback) callback({status:1});

  }catch(error) {console.log(error)};
}


export function* mapVersionlist({AreaName,Version,callback}){
  try {
    const url = m1+ '/map/?AreaName='+AreaName;
    const data = yield call(request,url,{
      method:'GET'
    })
    if(callback) callback(data['Data']);

  }catch(error) {console.log(error)};
}


export function* uploadMsg({AreaName,FileURL,CreatedBy,Reason,Initversion,callback}){
  try {
    const url = m1+ '/map/?AreaName='+AreaName;
    const data = yield call(request,url,{
      method:'POST',
      body:{
        FileURL:{
          map:{
            raw:FileURL.Raw,
            product:FileURL.Map,
          },
          route:FileURL.Route,
          curb:FileURL.Curb
        },
        CreatedBy,
        Reason,
        Initversion
      }
    })
    if(callback) callback(data['Data']);

  }catch(error) {console.log(error)};
}



export function* fuzzySearch({keyword,callback}){
  console.log(keyword)
  try {
    const url = m1+ '/areas/inputtip/search?keyword='+keyword;
    const data = yield call(request,url,{
      method:'GET'
    })
    console.log(data)
    if(callback) callback(data['Data']);

  }catch(error) {console.log(error)};
}



export function* watchFuzzySearch() {
  yield takeLatest('FUZZY_SEARCH', fuzzySearch);
}




export function* watchSearcharea() {
  yield takeLatest('AREA_SEARCH', searcharea);
}


export function* watchFetchArea () {
  yield takeLatest('AREA_FETCH', fetch);
}

export function* watchFetchLoopStation () {
  yield takeLatest('LOOPSTATIONS_FETCH', fetchLoopStaions);
}

export function* watchAddArea () {
  yield takeLatest('AREA_ADD', add);
}

export function* watchDeleteArea () {
  yield takeLatest('AREA_DELETE', deletearea);
}

export function* watchAddStation () {
  yield takeLatest('STATION_ADD', addstation);
}

export function* watchDeleteStation () {
  yield takeLatest('STATION_DELETE', deletestation);
}

export function* watchAllcarstatus () {
  yield takeLatest('CARS_STATE', carsall);
}

export function* watchAreaConfig () {
  yield takeLatest('AREA_CONFIG', config);
}

function* watchRouteCreate() {
  yield takeLatest('ROUTE_CREATE',createroute);
}

function* watchStationExchange() {
  yield takeLatest('STATION_EXCHANGE',exchangestation);
}

function* watchLoopStationDelete() {
  yield takeLatest('LOOPSTATION_DELETE',deleteloopstation);
}

function* watchLoopStationAdd() {
  yield takeLatest('LOOPSTATION_ADD',addloopstation);
}

function* watchSearchRoute() {
  yield takeLatest('ROUTE_SEARCH',searchroute);
}



function* watchStationPolish() {
  yield takeLatest('STATION_POLISH',polishstation);
}


function* watchStationSearch() {
  yield takeLatest('STATION_SEARCH',searchstation);
}


function* watchReportAdd() {
  yield takeLatest('REPORT_ADD',addreport);
}


function* watchReportList() {
  yield takeLatest('REPORT_LIST',reportlist);
}



function* watchReportDelete() {
  yield takeLatest('DELETE_REPORT',deletereport);
}

function* watchMapVersionlist() {
  yield takeLatest('VERSION_LIST',mapVersionlist);
}


function* watchUploadMsg() {
  yield takeLatest('UPLOAD_MSG',uploadMsg);
}









export default [
  watchFuzzySearch,
  watchFetchArea,
  watchSearcharea,
  watchFetchLoopStation,
  watchAddArea,
  watchDeleteArea,
  watchAddStation,
  watchDeleteStation,
  watchAllcarstatus,
  watchAreaConfig,
  watchRouteCreate,
  watchStationExchange,
  watchLoopStationDelete,
  watchLoopStationAdd,
  watchSearchRoute,
  watchStationPolish,
  watchStationSearch,
  watchReportAdd,
  watchReportList,
  watchReportDelete,
  watchMapVersionlist,
  watchUploadMsg
];
