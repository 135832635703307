import {setAuthority,clearAccessToken,setExpiretime,setUserinfo} from '../utils/authority'
import {
  take,
  fork,
  takeLatest,
  put,
  call,
  select,
  cancel,
  cancelled
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import request from '../utils/request';
import md5 from 'blueimp-md5';
import config from '../utils/config'

const {m1} = config

export function* getCaptchaid({callback}){
  const data = yield call(request, '/api/v1/pub/login/captchaid',{
    method: 'GET'
  })
  console.log(data.Data)
  if(callback)callback(data.Data.captcha_id)
}


export function* requestSms ({ type, phone, ...attrs }) {
  yield call(request, m1+'/register/sms', {
    method: 'POST',
    body: { phone }
  });
}

export function* logOut () {
  yield [
    call(request, '/api/v1/pub/login/exit', { method: 'POST' }),
    put({ type: 'login/logout' }),
    put({
      type: 'account/update',
      payload: null
    })
  ];
  clearAccessToken();

  window.location.href = '/acc/login';
}

export function* logIn (username, password,captcha_id,captcha_code,callback) {
  try {
    yield put({ type: 'login/request' });
    // 登录成功之后用户信息写到全局State。
    const data = yield call(request, '/api/v1/pub/login', {
      method: 'POST',
      body: {
        user_name:username,
        password:md5(password),
        captcha_code,
        captcha_id
      }
    });
    const token_data = `${data.Data.token_type} ${data.Data.access_token}`
    setAuthority(token_data)
    setUserinfo(username)
    setExpiretime(data.Data.expires_at)
    yield [
      put({ type: 'login/success' }),
      yield put({
        type: 'account/update',
        payload: { username }
      })
    ];
    // 跳转到管理后台主页面
    yield put(push('/'));
  } catch (error) {
    if(callback)callback({status:false})
    yield put({ type: 'LOGIN_ERROR' });
    yield put({
      type: 'login/failed',
      payload: error.message
    });
  } finally {
    if (yield cancelled()) {
      // ... put special cancellation handling code here
      yield put({ type: 'login/logout' });
    }
  }
}

export function* signUp ({ type, username, password, ...attrs }) {
  try {
    yield put({ type: 'signup/request' });
    const result = yield call(request, '/register', {
      method: 'POST',
      body: {
        username,
        password,
        phone: attrs.phone,
        code: attrs.code
      }
    });
    if (result.Status !== 0) {
      yield put({ type: 'signup/success' });
      yield put(push({
        pathname: '/acc/register-result',
        state: { account: username }
      }));
    } else {
      yield put({
        type: 'signup/failed',
        payload: '已经注册'
      });
    }

  } catch (error) {
    yield put({
      type: 'signup/failed',
      payload: error.message
    });
  }
}

export function* currentAsync () {
  try {
    const login = yield select(state => state.login);
    if (login.status !== 'success') {
      const account = yield call(request, '/api/v1/pub/current/user', {
        method: 'GET'
      });
      if(account.Data === undefined) return null;
      window.localStorage.setItem("user_info",JSON.stringify(account.Data))
      yield put({
        type: 'account/update',
        payload: {
          username: account.Data.user_name,
          user_id: account.Data.record_id
        }
      });
    }
  } catch (error) {
    yield put(push('/exception/403'));
  }
}

export function* watchSms () {
  yield takeLatest('REQUEST_SMS', requestSms);
}

export function* watchLogin () {
  while (true) {
    const { username, password ,captcha_id,captcha_code,callback} = yield take('LOGIN_REQUEST');

    // fork return a Task object
    const task = yield fork(logIn, username, password,captcha_id,captcha_code,callback);
    const action = yield take(['LOGOUT', 'LOGIN_ERROR']);
    if (action.type === 'LOGOUT') {
      yield cancel(task);
    }
  }
}

export function* watchLogout () {
  yield takeLatest('LOGOUT_REQUEST', logOut);
}

export function* watchSignup () {
  yield takeLatest('SIGNUP_REQUEST', signUp);
}

export function* watchCurrentAsync () {
  yield takeLatest('CURRENT_REQUEST', currentAsync);
}

export function* watchCaptchaid () {
  yield takeLatest('GET_CAPTCHAID',getCaptchaid)
}




export default [
  watchSms,
  watchLogin,
  watchSignup,
  watchLogout,
  watchCurrentAsync,
  watchCaptchaid
];
