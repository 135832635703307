import { takeLatest, call, put, select } from "redux-saga/effects";
import request from "../utils/request";
import { getDay } from "../utils/utils";
import con from "../utils/config";
import { message } from "antd";

const { m1 } = con;
export function* fetch({ callback, IsReport }) {
  try {
    const uri = IsReport
      ? m1 + "/search_cars?IsReport=true"
      : m1 + "/search_cars";
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (callback) callback(data["Data"]);
    yield put({
      type: "car/success",
      payload: data["Data"],
    });
  } catch (error) {
    console.log(error);
  }
}

/**
 * 关于小车的删除
 */

export function* cardelete({ CarId, callback }) {
  try {
    const uri = m1 + "/cars/" + CarId;
    const data = yield call(request, uri, {
      method: "DELETE",
    });
    if (callback) callback(JSON.parse(data));
    if (JSON.parse(data)) {
      const car = yield select((state) => state.car);
      const cars = car.data.filter((item) => item.Id !== CarId);
      yield put({
        type: "car/success",
        payload: cars,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * 园区查找
 */

export function* searchdistrict({ AreaName }) {
  try {
    const uri = m1 + "/cars/?AreaName=" + AreaName;
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (data.Data != null) {
      yield put({
        type: "car/success",
        payload: data["Data"],
      });
    } else {
      yield put({
        type: "car/datanull",
        payload: null,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * 未绑定小车获取
 */

export function* carlist() {
  try {
    const uri = m1 + "/cars/?AreaName=*";
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (data) {
      yield put({
        type: "car/unbindcarlist",
        payload: data["Data"],
      });
    }
  } catch (error) {
    console.log(error);
  }
}
/**
 * 按照小车的carID进行查找;组合园区Name
 */
export function* searchcarid({ CarID, AreaName }) {
  CarID = CarID.toString();
  AreaName = AreaName.toString();
  try {
    const uri = m1 + "/cars/?AreaName=" + AreaName + "&&Id=" + CarID;
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (data.Data != null) {
      yield put({
        type: "car/success",
        payload: data["Data"],
      });
    } else {
      yield put({
        type: "car/datanull",
        payload: null,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * 小车状态查找
 */

export function* searchcarstatus({ Status }) {
  Status = parseInt(Status);
  try {
    const uri = m1 + "/cars/?Status=" + Status;
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (data.Data != null) {
      yield put({
        type: "car/success",
        payload: data["Data"],
      });
    } else {
      yield put({
        type: "car/datanull",
        payload: null,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * 综合查找
 */
export function* search({ IsReport, Id, AreaName, Status, callback }) {
  try {
    var dataArr = [];
    var uri = m1 + "/cars/?";
    for (var key in arguments[0]) {
      if (key !== "callback" && key !== "type" && arguments[0][key]) {
        var item = { key: key, value: arguments[0][key] };
        dataArr.push(item);
      }
    }
    dataArr.forEach((item, key) => {
      if (key === 0) {
        uri = uri + item.key + "=" + item.value;
      } else {
        uri = uri + "&" + item.key + "=" + item.value;
      }
    });
    console.log(uri);

    const data = yield call(request, uri, {
      method: "GET",
    });
    if (callback) callback(data["Data"]);
    yield put({
      type: "car/success",
      payload: data["Data"],
    });
  } catch (error) {
    console.log(error);
  }
}

/**
 * 小车绑定
 */
export function* carbind({ AreaName, CarId, callback }) {
  try {
    const uri = m1 + "/bind/?AreaName=" + AreaName + "&CarId=" + CarId;
    const data = yield call(request, uri, {
      method: "POST",
    });
    if (callback) callback(data);
    // if(data){
    //   // yield fetch();
    //   //yield carlist();
    // }
  } catch (error) {
    console.log(error);
  }
}

/**
 * 小车解绑
 */
export function* carunbind({ AreaName, CarId, callback }) {
  try {
    const uri = m1 + "/bind/?AreaName=" + AreaName + "&CarId=" + CarId;
    const data = yield call(request, uri, {
      method: "DELETE",
    });
    if (callback) callback(data);
    // if(data){
    //   yield fetch();
    // }
  } catch (error) {
    console.log(error);
  }
}

export function* carlock({ Door, Operation, carId, callback }) {
  try {
    const uri = m1 + "/car/door";
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        CarID: carId,
        Action: Operation,
        Door,
      },
    });
    if (callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* carlight({ Operation, carId, callback }) {
  try {
    const uri = m1 + "/car/light";
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        CarID: carId,
        Action: Operation,
      },
    });
    if (callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* carhonk({ Action, CarID, callback }) {
  try {
    const uri = m1 + "/car/honk";
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        CarID,
        Action,
      },
    });
    if (callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* carsuspend({ Action, CarID, callback }) {
  try {
    const uri = m1 + "/car/mode_switch";
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        CarID,
        Action,
      },
    });
    if (callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* carcontinue({ Action, CarID, callback }) {
  try {
    const uri = m1 + "/car/mode_switch";
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        CarID,
        Action,
      },
    });
    if (callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* modeswitch({ Action, CarID, callback }) {
  try {
    const uri = m1 + "/car/mode_switch";
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        CarID,
        Action,
      },
    });
    if (callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* mileage({ carID, callback }) {
  try {
    const uri = m1 + "/cars_state/mileage/" + carID + "/?Day=" + getDay("-");
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* carcarry({ id, callback }) {
  try {
    const uri = m1 + "/cars/goods/carry/?id=" + id;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：车辆软件列表
 * carid,page,size,status
 */
export function* carsoftware({ carid, page, size, status, callback }) {
  try {
    const uri =
      m1 +
      `/udrive/car/softwares?carid=${carid}&page=${page}&size=${size}&status=${status}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    yield put({
      type: "car/software",
      payload: data["Data"],
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：车辆软件一键升级
 * carid
 */

export function* softwareUpdate({ carid, callback }) {
  try {
    const uri = m1 + `/udrive/car/software/upgrade?carid=${carid}`;
    const data = yield call(request, uri, {
      method: "PUT",
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：车辆安装软件
 * carid softwares
 */

export function* carinstallSoftware({ carid, softwares, callback }) {
  try {
    const uri = m1 + "/udrive/car/software";
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        carid,
        softwares,
      },
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：车辆卸载软件
 * id
 */
export function* cardeleteSoftware({ id, callback }) {
  try {
    const uri = m1 + "/udrive/car/software";
    const data = yield call(request, uri, {
      method: "DELETE",
      body: {
        id,
      },
    });
    message.success(data.Message);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：车辆升级软件
 * id upgrade_id
 */
export function* carUpgradeSoftware({ upgrade_id, id, callback }) {
  console.log(upgrade_id, id);
  try {
    const uri = m1 + "/udrive/car/software";
    const data = yield call(request, uri, {
      method: "PUT",
      body: {
        upgrade_id,
        id,
      },
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* carsoftwareConfigs({ carid, gid, page, size, callback }) {
  try {
    const uri =
      m1 +
      `/udrive/car/software/confs?carid=${carid}&gid=${gid}&page=${page}&size=${size}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：查询车辆单个软件的单个配置
 * id
 */
export function* carOnlyconfig({ id, callback }) {
  try {
    const uri = m1 + `/udrive/car/software/conf?id=${id}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：编辑单个车辆配置
 * id reason info
 */
export function* editCarconfig({ id, reason, info, callback }) {
  try {
    const uri = m1 + "/udrive/car/software/conf";
    const data = yield call(request, uri, {
      method: "PUT",
      body: {
        id,
        reason,
        info,
      },
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 功能描述：查询车辆配置历史记录列表
 */
export function* carConfighistorylist({ id, page, size, callback }) {
  try {
    const uri =
      m1 + `/udrive/software/conf/historys?id=${id}&page=${page}&size=${size}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//下发扫描指令
export function* scanmode({ operation, carId, callback }) {
  try {
    const uri = m1 + `/car/auto_scan/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        operate: operation,
      },
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//获取扫描状态
export function* getscanmode({ carId, callback }) {
  try {
    const uri = m1 + `/car/auto_scan/${carId}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 *  获取预置位
 */
export function* getPresetPos({ carId, callback }) {
  try {
    const uri = m1 + `/car/preset_position/${carId}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    yield put({
      type: "car/presetpos",
      payload: data.Data,
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 *  删除预置位
 */
export function* deletePresetPos({ carId, name, callback }) {
  try {
    const uri = m1 + `/car/preset_position/${carId}`;
    const data = yield call(request, uri, {
      method: "DELETE",
      body: {
        name,
      },
    });
    console.log(data);
    if (callback) callback(data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 *  添加预置位
 */
export function* addPresetPos({ carId, name, callback }) {
  try {
    const uri = m1 + `/car/preset_position/${carId}`;
    const data = yield call(request, uri, {
      method: "PUT",
      body: {
        name,
      },
    });
    console.log(data);
    if (callback) callback(data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 *  移动到预置位
 */
export function* movetoPresetPos({ carId, name, callback }) {
  try {
    const uri = m1 + `/car/preset_position/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        name,
      },
    });
    console.log(data);
    if (callback) callback(data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *  获取巡航路线
 */

export function* getCruiselist({ carId, callback }) {
  try {
    const uri = m1 + `/car/cruise/${carId}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    console.log("------------------------------巡航路线-------");
    yield put({
      type: "car/cruiselist",
      payload: data.Data,
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *  删除巡航路线
 */

export function* deleteCruisel({ carId, name, point, callback }) {
  try {
    const uri = m1 + `/car/cruise/${carId}`;
    const data = yield call(request, uri, {
      method: "DELETE",
      body: {
        name,
        point,
      },
    });
    console.log(data);

    if (callback) callback(data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *  开始关闭巡航路线
 */

export function* changestatusCruisel({ carId, operate, name, callback }) {
  try {
    const uri = m1 + `/car/cruise/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        operate,
        name,
      },
    });
    console.log(data);
    if (callback) callback(data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *  添加巡航路线
 */

export function* addCruisel({
  carId,
  name,
  speed,
  stop,
  point,
  operate,
  callback,
}) {
  try {
    const uri = m1 + `/car/cruise/${carId}`;
    const data = yield call(request, uri, {
      method: "PUT",
      body: {
        name,
        speed,
        stop,
        point,
        operate,
      },
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* carlog({ AreaName, Id, callback }) {
  try {
    const uri = m1 + "/cars_state/?AreaName=" + AreaName + "&CarId=" + Id;
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (callback) callback(data.States);
  } catch (error) {
    console.log(error);
  }
}

export function* warnlight({ carId, operate, callback }) {
  try {
    const uri = m1 + `/car/warn_light/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        operate,
      },
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* carinfect({ carId, operate, callback }) {
  try {
    const uri = m1 + `/car/disinfection/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        operate,
      },
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* hornlist({ carID, callback }) {
  try {
    const uri = m1 + `/audio/?carID=${carID}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    yield put({
      type: "car/hornlist",
      payload: data.Data,
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* hornOpr({ callback }) {
  try {
    const uri = m1 + "/audio";
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    yield put({
      type: "car/hornlist",
      payload: data.Data,
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* playmusic({
  id,
  carID,
  play,
  title,
  times,
  interval,
  callback,
}) {
  try {
    const uri = m1 + "/audio";
    const data = yield call(request, uri, {
      method: "PUT",
      body: {
        carID,
        play,
        id,
        title,
        times,
        interval,
      },
    });
    console.log(data);
    yield put({
      type: "car/hornlist",
      payload: data.Data,
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* addmusic({ payload, callback }) {
  console.log(payload);
  try {
    const uri = m1 + "/audio";
    const data = yield call(request, uri, {
      method: "POST",
      body: payload,
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* deletemusic({ id, callback }) {
  try {
    const uri = m1 + "/audio";
    const data = yield call(request, uri, {
      method: "DELETE",
      body: {
        id,
      },
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//更改摄像机状态
export function* videostatus({ operate, carId, callback }) {
  try {
    const uri = m1 + `/car/camera/status/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        operate,
      },
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//抓拍
export function* shotpic({ carid, callback }) {
  try {
    const uri = m1 + `/udrive/capture/pic?carid=${carid}`;
    const data = yield call(request, uri, {
      method: "PUT",
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//更改录像状态

export function* videotape({ operate, carId, callback }) {
  try {
    const uri = m1 + `/car/localrecord/status/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        operate,
      },
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//修改摄像模式

export function* videomode({ status, carid, callback }) {
  try {
    const uri = m1 + "/udrive/capture/pattern";
    const data = yield call(request, uri, {
      method: "PUT",
      body: {
        carid,
        type: status,
      },
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//修改画面缩放（接口暂时没有，之后修改需要2020.11.27）

export function* carslider({ value, carid, callback }) {
  try {
    const uri = m1 + "/udrive/capture/pattern";
    const data = yield call(request, uri, {
      method: "PUT",
      body: {
        carid,
        value,
      },
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//查询车牌号列表

export function* carplate({ page, size, lpn, carid, callback }) {
  try {
    //&lpn=
    const uri =
      m1 + `/udrive/capture/lpnlist?carid=${carid}&page=${page}&size=${size}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//查询人脸照片

export function* carface({ page, size, carid, callback }) {
  try {
    const uri =
      m1 + `/udrive/capture/facelist?carid=${carid}&page=${page}&size=${size}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

//查询画面

export function* carpic({ page, size, carid, callback }) {
  try {
    const uri =
      m1 + `/udrive/capture/piclist?carid=${carid}&page=${page}&size=${size}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 *  画面放大缩小
 */

export function* carpicChange({ operate, command, carId, callback }) {
  try {
    const uri = m1 + `/car/focus/${carId}`;
    const data = yield call(request, uri, {
      method: "POST",
      body: {
        operate,
        command,
      },
    });
    if (callback) callback(data.Data.piclist);
  } catch (err) {
    console.log(err);
  }
}

export function* historyvideo({ streamid, starttime, endtime, callback }) {
  try {
    const uri =
      m1 +
      `/udrive/video/timeline.tl/?streamid=${streamid}&start=${starttime}&end=${endtime}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data.Data);
  } catch (err) {
    console.log(err);
  }
}

export function* carvideo({ streamid, start, end, callback }) {
  try {
    const uri =
      m1 +
      `/udrive/video/record.m3u8?streamid=${streamid}&start=${start}&end=${end}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data);
  } catch (err) {
    console.log(err);
  }
}

export function* anfangVideo({ streamid, callback }) {
  try {
    const uri = m1 + `/udrive/live/pulllink/?streamid=${streamid}`;
    const data = yield call(request, uri, {
      method: "GET",
    });
    console.log(data);
    if (callback) callback(data);
  } catch (err) {
    console.log(err);
  }
}

// 车型设备服务，查询车辆信息
export function* newCarList({
  keyword,
  area_id,
  car_type_name,
  usage_state,
  favorites,
  user_id,
  page,
  size,
  callback,
}) {
  const uri = m1+`/cardevice/carinfo/list`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      keyword,
      area_id,
      car_type_name,
      usage_state,
      favorites,
      user_id,
      page,
      size,
    },
  });
  if (callback) callback(data);
}

export function* resetCarMileage({ carId, callback }) {
  try {
    const uri = m1 + `/car/clear_subtotal_mileage/${carId}`
    const data = yield call(request, uri, {
      method: 'GET'
    });
    console.log('hptest456', data);
    callback(data)
  } catch (e) {
    console.log(e);
  }
}

export function* clearMileage() {
  yield takeLatest('CAR_CLEAR_MILEAGE', resetCarMileage);
}

// 车型设备服务，删除车辆信息
export function* carDeleteMessage({ car_info_id, callback }) {
  const uri =  m1 +`/cardeivce/carinfo/${car_info_id}`;
  const data = yield call(request, uri, {
    method: "DELETE",
  });
  if (callback) callback(data);
}

// 车型设备服务，添加车辆信息
export function* carAddMessage({
  car_id_prefix,
  car_custom_no,
  car_name,
  vin_code,
  car_type_name,
  usage_state,
  area_id,
  remark,
  carriage,
  callback,
}) {
  const uri =  m1 +`/cardevice/carinfo`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      car_id_prefix,
      car_custom_no,
      car_name,
      vin_code,
      car_type_name,
      usage_state,
      area_id,
      remark,
      carriage,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，更新车辆信息
export function* carUpdateMessage({
  car_info_id,
  car_name,
  car_type_name,
  usage_state,
  area_id,
  remark,
  carriage,
  callback,
}) {
  const uri =  m1 +`/cardevice/carinfo`;
  const data = yield call(request, uri, {
    method: "PUT",
    body: {
      car_info_id,
      car_name,
      car_type_name,
      usage_state,
      area_id,
      remark,
      carriage,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，更新车辆实体设备信息
export function* carEntityMessage({
  car_info_id,
  device_id,
  serial,
  callback,
}) {
  const uri =  m1 +`/cardevice/carinfo/device`;
  const data = yield call(request, uri, {
    method: "PUT",
    body: {
      car_info_id,
      device_id,
      serial,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，删除车型
export function* carDeleteModel({ car_type_name, callback }) {
  const uri =  m1 +`/cardevice/cartype`;
  const data = yield call(request, uri, {
    method: "DELETE",
    body: {
      car_type_name,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，添加车型
export function* carAddModel({
  car_series_name,
  custom_no,
  state,
  remark,
  callback,
}) {
  const uri = m1 + `/cardevice/cartype`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      car_series_name,
      custom_no,
      state,
      remark,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，更新车型
export function* carUpdateModel({ car_type_name, state, remark, callback }) {
  const uri =  m1 +`/cardevice/cartype`;
  const data = yield call(request, uri, {
    method: "PUT",
    body: {
      car_type_name,
      state,
      remark,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，删除车型设备
export function* carDeleteEntity({ car_type_name, device_id, callback }) {
  const uri =  m1 +`/cardevice/cartype/device`;
  const data = yield call(request, uri, {
    method: "DELETE",
    body: {
      car_type_name,
      device_id,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，添加车型设备
export function* carAddEnity({
  car_type_name,
  device_type,
  device_name,
  device_no,
  count,
  callback,
}) {
  const uri = m1 + `/cardevice/cartype/device`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      car_type_name,
      device_type,
      device_name,
      device_no,
      count,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，修改车型设备
export function* carUpdateEnity({
  car_type_name,
  device_id,
  device_no,
  count,
  callback,
}) {
  const uri =  m1 +`/cardevice/cartype/device`;
  const data = yield call(request, uri, {
    method: "PUT",
    body: {
      car_type_name,
      device_id,
      device_no,
      count,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，继承车型设备
export function* carInheritEnity({
  from_car_type_name,
  to_car_type_name,
  callback,
}) {
  const uri =  m1 +`/cardevice/cartype/inherit`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      from_car_type_name,
      to_car_type_name,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，查询车型
export function* carModelList({
  keyword,
  car_series_name,
  state,
  page,
  size,
  callback,
}) {
  const uri =  m1 +`/cardevice/cartype/list`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      keyword,
      car_series_name,
      state,
      page,
      size,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，同步车型
export function* carSyncModel({ car_type_name, callback }) {
  const uri = m1 + `/cardevice/cartype/sync`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      car_type_name,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，删除设备信息
export function* carDeleteEquipment({ device_id, callback }) {
  const uri = m1 + `/cardevice/device`;
  const data = yield call(request, uri, {
    method: "DELETE",
    body: {
      device_id,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，添加设备信息
export function* carAddEquipment({
  device_type,
  device_name,
  device_no,
  state,
  remark,
  callback,
}) {
  const uri = m1 + `/cardevice/device`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      device_type,
      device_name,
      device_no,
      state,
      remark,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，更新设备信息
export function* carUpdateEquipment({
  device_id,
  device_type,
  device_name,
  device_no,
  state,
  remark,
  callback,
}) {
  const uri =  m1 +`/cardevice/device`;
  const data = yield call(request, uri, {
    method: "PUT",
    body: {
      device_id,
      device_type,
      device_name,
      device_no,
      state,
      remark,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，查询设备信息
export function* carQueryEquipment({
  keyword,
  state,
  device_type,
  device_name,
  page,
  size,
  callback,
}) {
  const uri = m1 + `/cardevice/device/list`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      keyword,
      device_type,
      device_name,
      state,
      page,
      size,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，删除设备类型
export function* carDeleteType({ device_type, callback }) {
  const uri =  m1 +`/cardevice/devicetype`;
  const data = yield call(request, uri, {
    method: "DELETE",
    body: {
      device_type,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，创建设备类型
export function* carCreateType({ device_type, callback }) {
  const uri =  m1 +`/cardevice/devicetype`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      device_type,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，查询设备分类与设备名
export function* carTypeEquipment({
  device_type,
  device_name,
  device_no,
  callback,
}) {
  const uri =  m1 +`/cardevice/devicetype/list`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      device_type,
      device_name,
      device_no,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，删除设备名
export function* carDeleteName({ device_type, device_name, callback }) {
  const uri =  m1 +`/cardevice/devicetype/name`;
  const data = yield call(request, uri, {
    method: "DELETE",
    body: {
      device_type,
      device_name,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，创建设备名
export function* carCreateName({ device_type, device_name, callback }) {
  const uri =  m1 +`/cardevice/devicetype/name`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      device_type,
      device_name,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，删除实体设备
export function* carEntityDevice({ entity_id, callback }) {
  const uri =  m1 +`/cardevice/entitydevice`;
  const data = yield call(request, uri, {
    method: "DELETE",
    body: {
      entity_id,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，创建实体设备
export function* carCreateEntityDevice({
  device_id,
  serial,
  callback,
}) {
  const uri =  m1 +`/cardevice/entitydevice`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      device_id,
      serial,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，查询实体设备
export function* carQueryEntityDevice({
  keyword,
  state,
  device_type,
  car_info_id,
  page,
  size,
  device_id,
  callback,
}) {
  const uri =  m1 +`/cardevice/entitydevice/list`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      keyword,
      device_type,
      car_info_id,
      state,
      page,
      size,
      device_id,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，关注或者取消关注车辆
export function* carFavorites({ user_id, car_info_id, flag, callback }) {
  const uri = m1 + `/cardevice/carinfo/favorites`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      user_id,
      car_info_id,
      flag,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，获取车系列名列表
export function* carSeriesList({ callback }) {
  const uri =  m1 +`/cardevice/carseries`;
  const data = yield call(request, uri, {
    method: "GET",
  });
  if (callback) callback(data);
}

// 车型设备服务，查询车辆实体设备列表
export function* carEntityList({ car_info_id, device_type, keyword, state, page, size, callback }) {
  const uri =  m1 +`/cardevice/carinfo/entitydevice/list`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      car_info_id, device_type, keyword, state,
      page,
      size,
    },
  });
  if (callback) callback(data);
}

// 车型设备服务，查询车型设备列表
export function* carTypeList({ car_type_name, keyword, device_type, page, size, callback }) {
  const uri =  m1 +`/cardevice/cartype/device/list`;
  const data = yield call(request, uri, {
    method: "POST",
    body: {
      car_type_name,
      keyword,
      device_type,
      page,
      size,
    },
  });
  if (callback) callback(data);
}

export function* newCarListFetch() {
  yield takeLatest("NEW_CAR_LIST", newCarList);
}

export function* watchCarDeleteMessage() {
  yield takeLatest("CAR_DELETE_MESSAGE", carDeleteMessage);
}

export function* watchCarAddMessage() {
  yield takeLatest("CAR_ADD_MESSAGE", carAddMessage);
}

export function* watchCarUpdateMessage() {
  yield takeLatest("CAR_UPDATE_MESSAGE", carUpdateMessage);
}

export function* watchCarEntityMessage() {
  yield takeLatest("CAR_ENTITY_MESSAGE", carEntityMessage);
}

export function* watchCarDeleteModel() {
  yield takeLatest("CAR_DELETE_MODEL", carDeleteModel);
}

export function* watchCarAddModel() {
  yield takeLatest("CAR_ADD_MODEL", carAddModel);
}

export function* watchCarUpdateModel() {
  yield takeLatest("CAR_UPDATE_MODEL", carUpdateModel);
}

export function* watchCarDeleteEntity() {
  yield takeLatest("CAR_DELETE_ENITY", carDeleteEntity);
}

export function* watchCarAddEnity() {
  yield takeLatest("CAR_ADD_ENITY", carAddEnity);
}

export function* watchCarUpdateEnity() {
  yield takeLatest("CAR_UPDATE_ENITY", carUpdateEnity);
}

export function* watchCarInheritEnity() {
  yield takeLatest("CAR_INHERIT_ENITY", carInheritEnity);
}

export function* watchCarModelList() {
  yield takeLatest("CAR_MODEL_LIST", carModelList);
}

export function* watchCarSyncModel() {
  yield takeLatest("CAR_SYNC_MODEL", carSyncModel);
}

export function* watchCarDeleteEquipment() {
  yield takeLatest("CAR_DELETE_EQUIPMENT", carDeleteEquipment);
}

export function* watchCarAddEquipment() {
  yield takeLatest("CAR_ADD_EQUIPMENT", carAddEquipment);
}

export function* watchCarUpdateEquipment() {
  yield takeLatest("CAR_UPDATE_EQUIPMENT", carUpdateEquipment);
}

export function* watchCarQueryEquipment() {
  yield takeLatest("CAR_QUERY_EQUIPMENT", carQueryEquipment);
}

export function* watchCarDeleteType() {
  yield takeLatest("CAR_DELETE_TYPE", carDeleteType);
}

export function* watchCarCreateType() {
  yield takeLatest("CAR_CREATE_TYPE", carCreateType);
}

export function* watchCarTypeEquipment() {
  yield takeLatest("CAR_TYPE_EQUIPMENT", carTypeEquipment);
}

export function* watchCarDeleteName() {
  yield takeLatest("CAR_DELETE_NAME", carDeleteName);
}

export function* watchCarCreateName() {
  yield takeLatest("CAR_CREATE_NAME", carCreateName);
}

export function* watchCarEntityDevice() {
  yield takeLatest("CAR_ENTITY_DEVICE", carEntityDevice);
}

export function* watchCarCreateEntityDevice() {
  yield takeLatest("CARCREATE_ENTITY_DEVICE", carCreateEntityDevice);
}

export function* watchCarQueryEntityDevice() {
  yield takeLatest("CARQUERY_ENTITY_DEVICE", carQueryEntityDevice);
}

export function* watchCarFavorites() {
  yield takeLatest("CAR_FAVORITES", carFavorites);
}

export function* watchCarSeriesList() {
  yield takeLatest("CAR_SERIES_LIST", carSeriesList);
}

export function* watchCarEntityList() {
  yield takeLatest("CAR_ENTITY_LIST", carEntityList);
}

export function* watchCarTypeList() {
  yield takeLatest("CAR_TYPE_LIST", carTypeList);
}

export function* watchFetch() {
  yield takeLatest("CAR_FETCH", fetch);
}

/**
 * 关于小车的搜索
 */

export function* watchSearch() {
  yield takeLatest("CAR_SEARCH", search);
}

/**
 * 关于小车的绑定以及解绑
 */
export function* watchCarbind() {
  yield takeLatest("BIND_CAR", carbind);
}

export function* watchCarunbind() {
  yield takeLatest("UNBIND_CAR", carunbind);
}

/**
 * 关于小车的删除
 */
export function* watchCardelete() {
  yield takeLatest("CAR_DELETE", cardelete);
}

export function* watchCarunbindList() {
  yield takeLatest("CARUNBIND_LIST", carlist);
}

export function* watchCarLock() {
  yield takeLatest("CAR_LOCK", carlock);
}

export function* watchCarLight() {
  yield takeLatest("CAR_LIGHT", carlight);
}

export function* watchCarHonk() {
  yield takeLatest("HONK_WARNING", carhonk);
}

export function* watchCarSuspend() {
  yield takeLatest("CAR_SUSPEND", carsuspend);
}

export function* watchCarContinue() {
  yield takeLatest("CAR_CONTINUE", carcontinue);
}

export function* watchModeSwitch() {
  yield takeLatest("CAR_MODE_SWITCH", modeswitch);
}

export function* watchLastmileage() {
  yield takeLatest("LAST_MILRAGE", mileage);
}

export function* watchCarcarry() {
  yield takeLatest("CAR_CARRY", carcarry);
}

export function* watchCarSoftware() {
  yield takeLatest("CAR_SOFTWARE", carsoftware);
}

export function* watchSoftwareUpdate() {
  yield takeLatest("CARSOFTWARE_UPDATE", softwareUpdate);
}

export function* watchSoftwareInstall() {
  yield takeLatest("CARSOFTWARE_INSTALL", carinstallSoftware);
}

export function* watchSoftwareDelete() {
  yield takeLatest("CARSOFTWARE_DELETE", cardeleteSoftware);
}

export function* watchSoftwareUpgrade() {
  yield takeLatest("CARSOFTWARE_UPGRDE", carUpgradeSoftware);
}

export function* watchSoftwareOnlyConfig() {
  yield takeLatest("CARSOFTWARE_ONLYCONFIG", carOnlyconfig);
}

export function* watchSoftwareConfigEdit() {
  yield takeLatest("CARSOFTWARE_CONFIGEDIT", editCarconfig);
}

export function* watchSoftwareConfighistorylist() {
  yield takeLatest("CARSOFTWARE_CONFIGHISTORYLIST", carConfighistorylist);
}

export function* watchSoftwareConfigs() {
  yield takeLatest("SOFTWARE_CONFIGS", carsoftwareConfigs);
}

export function* watchScanmode() {
  yield takeLatest("CAR_SCAN_MODE", scanmode);
}

export function* watchGetscanmode() {
  yield takeLatest("CAR_GET_SCAN_MODE", getscanmode);
}

export function* watchGetPresetpos() {
  yield takeLatest("CAR_PRESETPOS", getPresetPos);
}

export function* watchDeletePresetpos() {
  yield takeLatest("CAR_DELETE_PRESETPOS", deletePresetPos);
}
export function* watchAddPresetpos() {
  yield takeLatest("CAR_ADD_PRESETPOS", addPresetPos);
}
export function* watchMovetoPresetpos() {
  yield takeLatest("CAR_MOVE_PRESETPOS", movetoPresetPos);
}

export function* watchGetCruise() {
  yield takeLatest("CAR_GET_CRUISE", getCruiselist);
}

export function* watchDeleteCruise() {
  yield takeLatest("CAR_DEL_CRUISE", deleteCruisel);
}

export function* watchChangeCruise() {
  yield takeLatest("CAR_CHANGE_CRUISE", changestatusCruisel);
}

export function* watchAddCruise() {
  yield takeLatest("CAR_ADD_CRUISE", addCruisel);
}

export function* watchCarLog() {
  yield takeLatest("CAR_LOG", carlog);
}

export function* watchWarnlight() {
  yield takeLatest("CAR_WARN_LIGHT", warnlight);
}

export function* watchInfect() {
  yield takeLatest("CAR_INFECT", carinfect);
}

export function* watchHornlist() {
  yield takeLatest("CAR_HORN_LIST", hornlist);
}

export function* watchPlayMusic() {
  yield takeLatest("CAR_PLAY_MUSIC", playmusic);
}

export function* watchAddMusic() {
  yield takeLatest("CAR_PLAY_ADD", addmusic);
}

export function* watchDeleteMusic() {
  yield takeLatest("CAR_DELETE_PLAY", deletemusic);
}

export function* watchChangeVideostatus() {
  yield takeLatest("CAR_VIDOSTATUS", videostatus);
}

export function* watchShotPic() {
  yield takeLatest("CAR_SHOT", shotpic);
}

export function* watchVideoTape() {
  yield takeLatest("CAR_VIDEO_TAPE", videotape);
}

export function* watchVideoMode() {
  yield takeLatest("CAR_VIDEO_MODE", videomode);
}

export function* watchCarSlider() {
  yield takeLatest("CAR_SLIDER", carslider);
}

export function* watchCarPlate() {
  yield takeLatest("CAR_PLATE", carplate);
}

export function* watchFace() {
  yield takeLatest("CAR_FACE", carface);
}

export function* watchCarpic() {
  yield takeLatest("CAR_PIC", carpic);
}

export function* watchCarpicChange() {
  yield takeLatest("CAR_CHANGE_PIC", carpicChange);
}

export function* watchHistoryvideolist() {
  yield takeLatest("CAR_HISTORY_VIDEO", historyvideo);
}

export function* watchCarvideo() {
  yield takeLatest("CAR_VIDEO", carvideo);
}

export function* watchAnfangVideo() {
  yield takeLatest("CAR_ANFANG_VIDEO", anfangVideo);
}

export default [
  newCarListFetch,
  watchCarDeleteMessage,
  watchCarAddMessage,
  watchCarUpdateMessage,
  watchCarEntityMessage,
  watchCarDeleteModel,
  watchCarAddModel,
  watchCarUpdateModel,
  watchCarDeleteEntity,
  watchCarAddEnity,
  watchCarUpdateEnity,
  watchCarInheritEnity,
  watchCarModelList,
  watchCarSyncModel,
  watchCarDeleteEquipment,
  watchCarAddEquipment,
  watchCarUpdateEquipment,
  watchCarQueryEquipment,
  watchCarDeleteType,
  watchCarCreateType,
  watchCarTypeEquipment,
  watchCarDeleteName,
  watchCarCreateName,
  watchCarEntityDevice,
  watchCarCreateEntityDevice,
  watchCarQueryEntityDevice,
  watchCarFavorites,
  watchCarSeriesList,
  watchCarEntityList,
  watchCarTypeList,

  clearMileage,
  watchFetch,
  watchSearch,
  watchCarbind,
  watchCarunbind,
  watchCardelete,
  watchCarunbindList,
  watchCarLock,
  watchCarLight,
  watchCarHonk,
  watchCarSuspend,
  watchCarContinue,
  watchLastmileage,
  watchCarcarry,
  watchCarSoftware,
  watchSoftwareUpdate,
  watchSoftwareInstall,
  watchSoftwareDelete,
  watchSoftwareUpgrade,
  watchSoftwareOnlyConfig,
  watchSoftwareConfigEdit,
  watchSoftwareConfighistorylist,
  watchSoftwareConfigs,
  watchScanmode,
  watchGetscanmode,
  watchGetPresetpos,
  watchDeletePresetpos,
  watchAddPresetpos,
  watchMovetoPresetpos,
  watchGetCruise,
  watchDeleteCruise,
  watchChangeCruise,
  watchAddCruise,
  watchCarLog,
  watchModeSwitch,
  watchWarnlight,
  watchInfect,
  watchHornlist,
  watchPlayMusic,
  watchAddMusic,
  watchChangeVideostatus,
  watchShotPic,
  watchVideoTape,
  watchVideoMode,
  watchCarSlider,
  watchCarPlate,
  watchFace,
  watchCarpic,
  watchCarpicChange,
  watchDeleteMusic,
  watchHistoryvideolist,
  watchCarvideo,
  watchAnfangVideo,
];
