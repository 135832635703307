import { initState } from "../variables/carState";
export const namespace = 'carwebsocket';

const initwsState ={
    status:'未连接',
    isSuccess:false,
    ws:null,
    msg:"",
    carstatus:null,
    startTime:null,
    plannedroute:null,
    endTime:null,
    takeover:null,
    hardwarewarn:null,
    drivingwarn:null,
    realtimeroute:null,
    cargps:[],
    plannedWarning:0,
    endWarning:0,
    realtimeGPS:[],
    messages:[],
    errcodeMsg:[],
    blacklpncmdArr:[],
    errcodeArr:[],
    isconnected:false,
    capturenotify:{
        name:'',
        object:''
    },
    blacklen:0,
    presetposition:0,
    cruisemsg:0,
    carState:initState()
}

export default function carwebsocket(state=initwsState,action) {
    switch (action.type){
        case `${namespace}/success`:
            return{
                ...state,
                status:"连接成功",
                isSuccess:true,
                isconnected:true
            }
        case `${namespace}/fall`:
            state.messages = [];
            state.carstatus = null;
            return{
                ...state,
                status:"未连接",
                msg:"",
                isSuccess:false,
                isconnected:false
            }
        
        case `${namespace}/carstatus`:
            //过滤处理；扯断发过来没有经纬度字段；处理显示上一个gps，不会出现跳动闪烁现象；并且过滤掉初始不存在情况
            var tempdata = action.data;
            if(!tempdata.longitude&&state.carstatus){
                tempdata.longitude = state.carstatus.longitude||0
                tempdata.latitude = state.carstatus.latitude||0
            }
            return {
            ...state,
            pending:false,
            carstatus:tempdata
            }

        case `${namespace}/taskstart`:
            state.realtimeGPS =[];
            return {
            ...state,
            pending:false,
            startTime:action.data.startTime
            }

        case `${namespace}/plannedWarning`:
        //有待测试
            state.realtimeGPS =[];
            return {
            ...state,
            pending:false,
            plannedWarning:action.data,
            endWarning:0
            }

        case `${namespace}/taskend`:
        //有待测试
        state.realtimeGPS =[];
            return {
            ...state,
            pending:false,
            endWarning:action.data,
            plannedWarning:0
            }
        case `${namespace}/refresh_realtimegps`:
            state.realtimeGPS =[];
            return {
                ...state
            }

        case `${namespace}/takeover`:
            return {
            ...state,
            pending:false,
            takeover:action.data
            }

        case `${namespace}/hardwarewarn`:
            return {
            ...state,
            pending:false,
            hardwarewarn:action.data
            }

        case `${namespace}/drivingwarn`:
            return {
            ...state,
            pending:false,
            drivingwarn:action.data
            }

        case `${namespace}/returnmsg`:
            return{
                ...state,
                msg:action.data
            }
        
        case `${namespace}/cargps`:
            return {
                ...state,
                pending: false,
                cargps:  action.payload
            }
        case `${namespace}/realtimecargps`:
            console.log(action.data)
            state.realtimeGPS.push(action.data);
            return {
                ...state
            }

        case `${namespace}/messages`:
            state.messages.push(action.data)
            return {
                ...state
            }
        case `${namespace}/errorcode`:
            const dataArr = state.errcodeMsg
            dataArr.push(...action.data.errcodeArr)
            var datastrArr = dataArr.map((item)=>{
                return JSON.stringify(item)
            })
            var dataSetArr = [...new Set(datastrArr)]
            var datafinal = dataSetArr.map((item)=>{
                return JSON.parse(item)
            })
            return {
                ...state,
                errcodeMsg:datafinal
            }
        case `${namespace}/blacklpncmd`:
            const blacklpncmdArr = state.blacklpncmdArr;
            console.log(action.data)
            console.log(action.data.alarmTime)
            blacklpncmdArr.push({alarmTime:action.data.alarmTime,gps:{...action.data.gps}})
            const temlen = state.blacklen+1
            return {
                ...state,
                blacklpncmdArr,
                blacklen:temlen
            }

        case `${namespace}/presetposition`:
            const tempPreset = state.presetposition + 1
            return {
                ...state,
                presetposition:tempPreset
            }
        case `${namespace}/cruisemsg`:
            const tempCruise = state.cruisemsg + 1
            return {
                ...state,
                cruisemsg:tempCruise
            }
        case `${namespace}/capturenotify`:
            console.log(action.payload)
            return {
                ...state,
                capturenotify:{...action.payload}
            }
        case `${namespace}/updateState`:
            const { chassisControlModeCode } = action.newState.carData
            let newState = JSON.parse(JSON.stringify(action.newState))
            if( chassisControlModeCode === 2 ){
                 newState.carData.chassisControlModeCode = state.carState.carData.chassisControlModeCode
            }
            return{
                ...state,
                carState:{...state.carState,...newState}
            }
        case `${namespace}/clearState`:
            return{
                ...state,
                carState:initState()
            }
        default:
            return state;
    }
}