import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../utils/request';
import con from '../utils/config'

const {m1} = con


export function* fetch ({page,size,istoday,callback}) {
  try {
    const uri = istoday?  `${m1}/udrive/lpn/alarmlist?istoday=${istoday}` : `${m1}/udrive/lpn/alarmlist?page=${page}&size=${size}`;
    const data = yield call(request, uri, {
      method: 'GET',
    })
    if (callback){callback(data.Data)}
    yield put({
      type: 'warnmanage/alarmlist',
      payload:data.Data 
    });


  } catch (error) {
    console.log(error);
  }
}
 

export function* getBllacklist ({page,size,callback}) {
    try {
      const uri = m1+ `/udrive/lpn/blacklist?page=${page}&size=${size}`;
      const data = yield call(request, uri, {
        method: 'GET',
      });
      if (callback){callback(data.Data)}
      yield put({
        type: 'warnmanage/blacklist',
        payload:data.Data 
      });
  
    } catch (error) {
      console.log(error);
    }
  }
   
  export function* deleteBllacklist ({id,callback}) {
    try {
      const uri = m1+ '/udrive/lpn/black'
      const data = yield call(request, uri, {
        method: 'DELETE',
        body:{
          id
        }
      });
      if (callback){callback(data.Data)}
    } catch (error) {
      console.log(error);
    }
  }
   
  export function* addBllacklist ({lpn,callback}) {
    try {
      const uri = m1+ '/udrive/lpn/black'
      const data = yield call(request, uri, {
        method: 'POST',
        body:{
          lpn
        }
      });
      if (callback){callback(data.Data)}
    } catch (error) {
      console.log(error);
    }
  }
   

export function* watchFetch(){
  yield takeLatest('ALARM_LIST',fetch)
}


export function* watchGetBlackList(){
    yield takeLatest('BLACK_LIST',getBllacklist)
}


export function* watchDeleteBlack(){
  yield takeLatest('DELETE_BLACK',deleteBllacklist)
}


export function* watchAddBlack(){
  yield takeLatest('ADD_BLACK',addBllacklist)
}
 

export default [
    watchFetch,
    watchGetBlackList,
    watchDeleteBlack,
    watchAddBlack
];
