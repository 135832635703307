import React, { Component } from 'react';
import { Icon} from 'antd';

export default class Loading extends Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
    render() {
        return(
            <div><Icon type="loading" />Loading..</div>
        );
      
    }
  }
  
 