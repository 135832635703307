import Loadable from 'react-loadable'
import Loading from '../src/components/Loading'

const routerConfig = [{
  path: '/',
  component: () => import('./layouts/BasicLayout'),
  children: [{
    path: 'overview',
    component:()=> Loadable({
      loader:() => import('./routes/Overview'),
      loading:Loading
    })
  },{
    path: 'user',
    component: () => Loadable({
      loader:() => import('./routes/User'),
      loading:Loading
    })
  },
  {
    path: 'manage/user',
    component: () => Loadable({
      loader:() => import('./routes/Manage/ManageUser'),
      loading:Loading
    })
  },
  {
    path: 'manage/role',
    component: () => Loadable({
      loader:() => import('./routes/Manage/ManageRole'),
      loading:Loading
    })
  },
  {
    path: 'manage/menu',
    component: () => Loadable({
      loader:() => import('./routes/Manage/ManageUrl'),
      loading:Loading
    })
  },
  {
    path: 'district',
    component: () => Loadable({
      loader:() => import('./routes/District'),
      loading:Loading
    })
  }, {
    path: 'district/district_add',
    component: () => Loadable({
      loader:() => import('./routes/District/DistrictAdd'),
      loading:Loading
    })
  },{
    path: 'district/:id/:areaId',
    component: () => Loadable({
      loader:() => import('./routes/District/DistrictDetails'),
      loading:Loading
    })
  },{
    path: 'district/districtconfig/:id/:areaId',
    component: () => Loadable({
      loader:() => import('./routes/District/DistrictConfig'),
      loading:Loading
    })
  }, {
    path: 'car/list',
    component: () => Loadable({
      loader:() => import('./routes/Car'),
      loading:Loading
    })
  },{
    path: 'car/newlist',
    component: () => Loadable({
      loader:() => import('./routes/Car/NewCarList'),
      loading:Loading
    })
  },
  // 设备管理
  {
    path: 'car/equipment',
    component: () => Loadable({
      loader:() => import('./routes/Car/EquipmentManage/index'),
      loading:Loading
    })
  },
  // 实体设备
  {
    path: 'car/entityEquipment',
    component: () => Loadable({
      loader:() => import('./routes/Car/EntityEquipment/index'),
      loading:Loading
    })
  },
   {
    path: 'car/software',
    component: () => Loadable({
      loader:() => import('./routes/Car/CarSoftware'),
      loading:Loading
    })
  },{
    path: 'car/software/config',
    component: () =>  Loadable({
      loader:() => import('./routes/Car/CarSoftwareConfig'),
      loading:Loading
    })
  },{
    path: 'car/cardetails',
    component: () =>  Loadable({
      loader:() => import('./routes/Car/CarDetails'),
      loading:Loading
    })
  },
  {
    path: 'car/carstatedetails',
    component: () =>  Loadable({
      loader:() => import('./routes/Car/CarStateDetails/index'),
      loading:Loading
    })
  },
  {
    path: 'car/carphysicalequipment',
    component: () =>  Loadable({
      loader:() => import('./routes/Car/CarPhysicalEquipment/index'),
      loading:Loading
    })
  },
  {
    path: 'car/cartype',
    component: () =>  Loadable({
      loader:() => import('./routes/Car/CarType/index'),
      loading:Loading
    })
  },
  {
    path: 'car/cartypedetail',
    component: () =>  Loadable({
      loader:() => import('./routes/Car/CarType/CarTypeDetail/index'),
      loading:Loading
    })
  },
  {
    path: 'task',
    component: () =>  Loadable({
      loader:() => import('./routes/Task'),
      loading:Loading
    })
  },{
    path: 'task/:AreaName/:CarId/:TaskId',
    component: () =>  Loadable({
      loader:() => import('./routes/Task/TaskDetails'),
      loading:Loading
    })
  },{
    path: 'software',
    component: () =>  Loadable({
      loader:() => import('./routes/Software'),
      loading:Loading
    })
  }, {
    path: 'software/history',
    component: () =>  Loadable({
      loader:() => import('./routes/Software/SoftwareHistory'),
      loading:Loading
    })
  },{
    path: 'software/config',
    component: () =>  Loadable({
      loader:() => import('./routes/Software/SoftwareConfig'),
      loading:Loading
    })
  },
  {
    path: 'warnmsg',
    component: () =>  Loadable({
      loader:() => import('./routes/WarnManage/BlackList'),
      loading:Loading
    })
  },
  {
    path: 'warnmsg/blacklist',
    component: () =>  Loadable({
      loader:() => import('./routes/WarnManage/BlackList'),
      loading:Loading
    })
  },
  {
    path: 'warnmsg/oneclickalarm',
    component: () => Loadable({
      loader:() => import('./routes/WarnManage/OneAlarm'),
      loading:Loading
    })
  }]
},  {
  path: 'acc',
  component: () => Loadable({
    loader:() => import('./layouts/UserLayout'),
    loading:Loading
  }),
  children: [{
    path: 'login',
    component: () => Loadable({
      loader:() => import('./routes/Account/Login'),
      loading:Loading
    })
  }, {
    path: 'register',
    component: () => Loadable({
      loader:() => import('./routes/Account/Register'),
      loading:Loading
    })
  }, {
    path: 'register-result',
    component: () => Loadable({
      loader:() => import('./routes/Account/RegisterResult'),
      loading:Loading
    })
  }]
}, {
  path: 'exception',
  component: () => Loadable({
    loader:() =>import('./layouts/BlankLayout'),
    loading:Loading
  }),
  children: [{
    path: '403',
    component: () => Loadable({
      loader:() => import('./routes/Exception/403'),
      loading:Loading
    })
  }, {
    path: '404',
    component: () => Loadable({
      loader:() => import('./routes/Exception/404'),
      loading:Loading
    })
  }, {
    path: '500',
    component: () => Loadable({
      loader:() => import('./routes/Exception/500'),
      loading:Loading
    })
  }]
}];

export default routerConfig;
