const localStorageKey = 'antd-pro-authority'
const expiretimeKey = 'antd-expire-time'
// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority() {
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  return localStorage.getItem(localStorageKey);
}

export function setAuthority(authority) {
  return localStorage.setItem(localStorageKey, authority);
}


export function clearAccessToken(){
  localStorage.removeItem(localStorageKey)
  localStorage.removeItem(expiretimeKey)
  localStorage.removeItem('udi-userinfo')
}


export function setExpiretime(expiretime){
  return localStorage.setItem(expiretimeKey, expiretime);
}

export function getExpiretime(){
  return localStorage.getItem(expiretimeKey);
}



export function setUserinfo(userinfo){
  return localStorage.setItem('udi-userinfo', userinfo);
}

export function getUserinfo(){
  return localStorage.getItem('udi-userinfo');
}


