import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../utils/request';
import md5 from 'blueimp-md5';

const manageUrl = '/api/v1'

export function* fetchUserList ({ querydata,callback, }) {
  try{
    var url = '/?'
    querydata&&Object.keys(querydata).forEach(function(key,index){
      index === 0 ? url = url + key +'='+ querydata[key]: url = url + '&'+ key +'='+ querydata[key]
    });
    const uri = url === '/?'?`${manageUrl}/users`:`${manageUrl}/users${url}`
    const data = yield call(request, uri, {
      method: 'GET'
    });
    yield put({
      type: 'manage/userlist',
      payload: data.Data
    });
  
    if(callback) {callback(data.Data)};
  }catch(err){
    console.log(err)
  }

}



export function* deleteUser ({id,callback}){
  try{
    const uri = `${manageUrl}/users/${id}`;
    const data = yield call(request,uri,{
      method:'DELETE'
    })
    if (callback) callback(data.Data)
  }catch(err){
    console.log(err)
  }

}

export function* ableUser ({id,callback}){
  try{
    const uri = `${manageUrl}/users/${id}/enable`;
    const data = yield call(request,uri,{
      method:'PATCH'
    })
    if (callback) callback(data.Data)
  }catch(err){console.log(err)}
  
}


export function* disableUser ({id,callback}){
  try{
    const uri = `${manageUrl}/users/${id}/disable`
    const data = yield call(request,uri,{
      method:'PATCH'
    })
    if (callback) callback(data.Data)
  }catch(err){console.log(err)}
  


}

export function* polishUser ({id,roles,user_name,real_name,status,callback}){
  try{
    const uri = `${manageUrl}/users/${id}`;
    const data = yield call(request,uri,{
      method:'PUT',
      body:{roles,record_id:id,real_name,user_name,status}
    })
    if (callback) callback(data.Data)
  }catch(err){console.log(err)}
 

}

export function* addUser ({payload,callback}){
  try{
    const uri = `${manageUrl}/users`;
   /*  payload.roles = payload.roles; */
    payload.status = 1;
    payload.password = md5(payload.password)
    const data = yield call(request,uri,{
      method:'POST',
      body:{...payload}
    })
    if (callback) callback(data.Data)
  }catch(err){console.log(err)}
  

}

export function* queryRealname({realName,current,pageSize,callback}){
  try{
    const uri = `${manageUrl}/users?realName=${realName}&current=${current}&pageSize=${pageSize}` 
    const data = yield call(request,uri,{
      method:'GET'
    })
    yield put({
      type: 'manage/userlist',
      payload: data.Data
    });
    if(callback) callback(data.Data)

  }catch(err){console.log(err)}
 
}


export function* queryRoles({ pagination, callback}){
  try{
    let uri = `${manageUrl}/roles`;
    if(pagination){
      uri = uri + '?'
      Object.keys(pagination).forEach(function(key,index){
        index === 0 ? uri = uri + key +'='+ pagination[key]: uri = uri + '&'+ key +'='+ pagination[key]
      });
    }
    const data = yield call(request,uri,{
      method:'GET'
    })
    yield put({
      type: 'manage/rolelist',
      payload: data.Data
    });
    if(callback) {callback(data.Data)};
  }catch(err){console.log(err)}
  
}

export function* addRole({payload,callback}){
  try{
    const uri = `${manageUrl}/roles`
    const data = yield call(request,uri,{
      method:'POST',
      body:{...payload,sequence:1000}
    })
    if(callback)callback(data.Data)
  }catch(err){console.log(err)}
  
}

export function* queryRoleitem({id}){
  try{
    const uri = `${manageUrl}/roles/${id}`
    const data = yield call(request,uri,{
      method:'GET'
    })
    console.log(data)
  }catch(err){console.log(err)}
  
}

export function* updateRole({payload,callback}){
  try{
    const uri = `${manageUrl}/roles/${payload.record_id}`
    const data = yield call(request,uri,{
      method:'PUT',
      body:{...payload,sequence:1000}
    })
    if(callback)callback(data.Data)
  }catch(err){console.log(err)}
  
}

export function* deleteRole({id,callback}){
  try{
    const uri = `${manageUrl}/roles/${id}`
    const data = yield call(request,uri,{
      method:'DELETE',
    })
    if(callback)callback(data.Data)
  }catch(err){
    console.log(err)
  }
}


export function* querymenus({querydata,callback}){
  try{
     //const uri = `${manageUrl}/pub/current/menutree`
    var url = '/?'
    querydata&&Object.keys(querydata).forEach(function(key,index){
      index === 0 ? url = url + key +'='+ querydata[key]: url = url + '&'+ key +'='+ querydata[key]
    });
    const uri = url === '/?'? `${manageUrl}/menus`:`${manageUrl}/menus${url}`
    const data = yield call(request,uri,{
      method:'GET'
    })
    if(callback)callback(data.Data)
    yield put({
      type: 'manage/menuslist',
      payload: data.Data.list
    });
  }catch(err){console.log(err)}
 
}

export function* addMenus({payload,callback}){
  try{
    const uri = `${manageUrl}/menus`
    const data = yield call(request,uri,{
      method:'POST',
      body:{...payload}
    })
    if(callback)callback(data.Data)
  }catch(err){console.log(err)}
 
}

export function* queryMenusitem({id}){
  try{
    const uri = `${manageUrl}/menus/${id}`
    const data = yield call(request,uri,{
      method:'GET'
    })
    console.log(data)
  }catch(err){console.log(err)}
  
}

export function* updateMenus({payload,callback}){
  try{
    const uri = `${manageUrl}/menus/${payload.record_id}`
    const data = yield call(request,uri,{
      method:'PUT',
      body:{...payload}
    })
    if(callback)callback(data.Data)
  }catch(err){console.log(err)}
 
}

export function* deleteMenus({id,callback}){
  try{
    const uri = `${manageUrl}/menus/${id}`
    const data = yield call(request,uri,{
      method:'DELETE'
    })
    if(callback)callback(data.Data)
  }catch(err){console.log(err)}
 
}



export function* fetchRoledetails({id,callback}){
  try{
    const uri = `${manageUrl}/roles/${id}`
    const data = yield call(request,uri,{
      method:'GET'
    })
    if(callback)callback(data.Data)
  }catch(err){console.log(err)}
 
}


export function* watchFetchRoledetails () {
  yield takeLatest('ROLE_DETAILS', fetchRoledetails);
}



export function* watchFetchuserlist () {
  yield takeLatest('USER_LIST', fetchUserList);
}

export function* watchDeleteUser () {
  yield takeLatest('DELETE_USER', deleteUser);
}


export function* watchAbleuser () {
  yield takeLatest('ABLE_USER', ableUser);
}

export function* watchDisableUser () {
  yield takeLatest('DISABLE_USER', disableUser);
}

export function* watchPolishUser () {
  yield takeLatest('POLISH_USER', polishUser);
}

export function* watchAddUser () {
  yield takeLatest('ADD_USER', addUser);
}

export function* watchQueryrealname(){
  yield takeLatest('CONFIRM_QUERY',queryRealname)
}




export function* watchQueryRole () {
  yield takeLatest('QUERY_ROLES', queryRoles);
}

export function* watchAddRole () {
  yield takeLatest('ADD_ROLES', addRole);
}

export function* watchQueryRoleitem () {
  yield takeLatest('QUERY_ROLEITEM', queryRoleitem);
}

export function* watchDeleteRole (){
  yield takeLatest('DELETE_ROLEITEM',deleteRole)
}

export function* watchUpdateRole(){
  yield takeLatest('UPDATE_ROLE',updateRole)
}





export function* watchQueryMenus () {
  yield takeLatest('QUERY_MENUS', querymenus);
}

export function* watchAddMenus () {
  yield takeLatest('ADD_MENUS', addMenus);
}

export function* watchQueryMenusitem () {
  yield takeLatest('QUERY_MENUSITEM', queryMenusitem);
}
export function* watchUpdateMenus () {
  yield takeLatest('UPDATE_MENUS', updateMenus);
}
export function* watchDeleteMenus () {
  yield takeLatest('DELETE_MENUS', deleteMenus);
}


export default [
    watchFetchuserlist,
    watchDeleteUser,
    watchAbleuser,
    watchDisableUser,
    watchPolishUser,
    watchAddUser,
    watchQueryrealname,
    watchQueryRole,
    watchAddRole,
    watchDeleteRole,
    watchUpdateRole,
    watchQueryRoleitem,
    watchQueryMenus,
    watchAddMenus,
    watchQueryMenusitem,
    watchUpdateMenus,
    watchDeleteMenus,
    watchFetchRoledetails
];
