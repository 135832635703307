export const namespace = 'software';

export default function software (state = {}, action) {
  switch (action.type) {
    case `${namespace}/list`:
        console.log(action)
        return {
            ...state,
            softwareList:action.payload.softwares,
            pending: true
        };
    case `${namespace}/expandlist`:
        console.log(action)
        console.log(action.payload)
        console.log(typeof action.payload)
        return {
            ...state,
            expandlist:action.payload,
            pending: true
        };
    

    default:
      return state;
  }
}
