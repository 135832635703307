
import { message } from 'antd';
import {getAuthority,setAuthority,getExpiretime} from './authority'
import moment from 'moment';
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  402: '用户没有权限,请联系管理员。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};


/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */



function checkAccessTokenExpires(expiresAt) {
  const now = moment().unix();
  if (expiresAt - now <= 0) {
    return -1;
  }
  if (expiresAt - now <= 600) {   //600s即10min以内有效时间就重新刷新token
    return 0;
  }
  return 1;
}

async function getAccessToken() {
  const tokenInfo = getAuthority();
  if (!tokenInfo) {
    return '';
  }

  if (checkAccessTokenExpires(getExpiretime()) === 0) {
  
    return fetch(`/api/v1/pub/refresh_token`,{
        method: 'POST',
        headers: {
          // Authorization: `${tokenInfo.token_type} ${tokenInfo.access_token}`,
          Authorization: tokenInfo
        },
      })
      .then(response => {
        console.log(response);
        const { status, data } = response;
        if (status === 200) {
          setAuthority(`${data.token_type} ${data.access_token}`);
          return `${data.token_type} ${data.access_token}`;
        }
        return '';
      });
  }
  return tokenInfo;
}


function checkStatus(response) {
  if(!response.status && response.Status){
    response.status = response.Status;
  }
  if(response.status !== 200 &&response.status !== 204 &&  response.status !== 401){
    try{
      response.json().then((data)=>{
        console.log(data)
        message.warning(data.Message|| data.message ||data.error.message)
      })
      return response
    }catch(err){
      console.log(err)
    }
  }

  const errortext = codeMessage[response.status] || response.statusText;
  if(response.status === 401 ){
    // response.then(function(data){
    //   message.warning(data.Message)
    // })
    window.location.href = "/acc/login"
  }


  

  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
}


export default async function request(url, options) {
  const authorized = await getAccessToken();
  const defaultOptions = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization':authorized
    },
  };
  const newOptions = { ...defaultOptions, ...options };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'||
    newOptions.method === 'PATCH' ||
    newOptions.method === 'GET'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      const {record_id:UserID,company:CompanyId} = !window.localStorage.getItem("user_info")  ? {} : JSON.parse(window.localStorage.getItem("user_info")) 
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization':authorized,
        CompanyId:CompanyId || null,
        UserID:UserID || null,
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        'Authorization':authorized,
        ...newOptions.headers,
      };
    }
  }

  return fetch(url, newOptions)
    .then(checkStatus)
    .then(response => {
      if(response.status !== 200 &&response.status !== 204 &&  response.status !== 401){
        return Promise.reject(response)
      }

      if (newOptions.method === 'DELETE' || response.status === 204) {
        // return response.text();
        return response.json();

      }
      // console.log(response)
      return response.json();
    })
}
