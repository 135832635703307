import moment from 'moment';
import { parse, stringify } from 'qs';


export function  stopBubble(e){
  if (e && e.stopPropagation) {//非IE浏览器
  　　 e.stopPropagation();
  }
  else {//IE浏览器
      window.event.cancelBubble = true;
  }
}

export function getDay( str) {
  var curDate = new Date();
  var preDate = new Date(curDate.getTime() - 24*60*60*1000);
  var day = preDate.getFullYear() + str + (preDate.getMonth()+1) +str + preDate.getDate();
  return day;
}

export function transDate (data){
  if(data === null || data === 'undefined') return '';
  let date = data.substr(0, 19).replace('T', ' ').replace(/-/g, '.')
  // let date = data.substr(0, 19).replace('T', ' ').replace(/-/g, '.').substr(0,10)  
  return date;
}



export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function accMul(arg1, arg2) {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  m += s1.split('.').length > 1 ? s1.split('.')[1].length : 0;
  m += s2.split('.').length > 1 ? s2.split('.')[1].length : 0;
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m;
}

export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟', '万']];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(accMul(num, 10 * 10 ** index)) % 10] + item).replace(/零./, '');
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function advancedQuery (query, action = {}) {
  if (action.pageSize) {
    query.limit(action.pageSize);
  }
  if (action.page && action.pageSize) {
    const offset = (action.page - 1) * action.pageSize;
    query.skip(offset);
  }
  if (action.sortField) {
    action.sortOrder = action.sortOrder || 'asc';
    action.sortOrder === 'asc'
      ? query.ascending(action.sortField)
      : query.descending(action.sortField);
  }
  if (action.filters) {
    Object.keys(action.filters).forEach(key => {
      query.matches(key, new RegExp(action.filters[key]))
    });
  }

  return query;
}




export function Utf8ArrayToStr(array) {
  if(!array)return
if(!array instanceof Array){
  return;
}
try{
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while(i < len) {
    c = array[i++];
    switch(c >> 4)
    {
        case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
        case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
        case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
        break;
        default:
          break;
    }
  }
  return out;
}catch(err){
    console.log(err)
  }
}


export function getFilenambypunct(str,punctuation){
  var first = str.lastIndexOf(punctuation);//取到文件名开始到最后一个点的长度
  var filesuffix = str.substring(0,first);//截取获得后缀名
  return filesuffix;
}


export function getobjLength (obj) {
  var i = 0;
  for (const index in obj) {
      i ++
      console.log(index)
  }
  return i
}


export function getHours () {
  var hours = [];
  for(var i =0;0<=i&&i<24;i++){
    hours.push(i)
  }
  return hours;
}


export function getMinutes () {
  var minutes = [];
  for(var i = 0;0<=i&&i<60;i++){
    minutes.push(i)
  }
  return minutes;
}


export function getMapcenter(arr){
  try{
    var zoomlat = 0;
    var zoomlong = 0;
    var arrlength = arr.length;
    arr.forEach((item) => {
      zoomlat = zoomlat + item.Latitude;
      zoomlong = zoomlong + item.Longitude;
    });
    return [zoomlong/arrlength,zoomlat/arrlength]
  }catch(err){
    console.log(err)
  }

}


export function getMapgps(arr){
try{
  const gps = [];
  for(var k = 0;k < arr.length;k++){
    gps[k] = {};
    gps[k].Id = k+1;
    gps[k].latitude = arr[k].Latitude;
    gps[k].longitude = arr[k].Longitude;
  }
  return gps;
}catch(err){
  console.log(err)
}
}


export function getminitaskList(arr){
  var minitasklisttemp = []
  arr.forEach((item,key)=>{
    if(key !== arr.length-1){
      minitasklisttemp.push(item.start_name)
    }else{
      minitasklisttemp.push(item.start_name)
      minitasklisttemp.push(item.end_name)
    }
  })
  return minitasklisttemp;
}

//纬度
export function checkLat (lat) {
  var latreg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/
  return latreg.test(lat);
}
//经度
export function checkLng(lng){
  var lngreg = /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10})$/
  return lngreg.test(lng);
}


function isCon(arr, val){ 
  for(var i=0; i<arr.length; i++){ 
      if(arr[i] === val) 
          return true; 
      }
          return false; 
}

//判断文件为audio
export function isAudio(filename){
  if(filename){
    //防止出问题，把获取到的所有结尾格式，全部转化为小写
    var filelast = filename.match(/^(.*)(\.)(.{1,8})$/)[3].toLowerCase();
    console.log(filelast)
    var right_type = ["avi","wmv","mpg","mpeg","mov","rm","ram","swf","flv","mp4","mp3","wma","avi","rm","rmvb","flv","mpg","mkv","mp2","wav"] 
    return isCon(right_type,filelast)
  }else{
    return false
  }

}


//tan x = y; 知道y的值，如何求x;已经知道tanx = y；求x角度值
export  function getTanDeg(tan) {
  var result = Math.atan(tan) / (Math.PI / 180);
  result = Math.round(result);
  return result;
}


/**
 *  车牌号校验 
 */

// export  function isVehicleNumber(vehicleNumber) {      
//   var result = false;      
 
//   if (vehicleNumber.length == 7){
//     var express = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
//     // var aaa = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|\([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/;
//     result = express.test(vehicleNumber);      
//   }     
//   console.log(result) 
//   return result;
// }

//车牌号验证方法

export function isVehicleNumber(vehicleNumber) {

  var xreg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;

  var creg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;

  if(vehicleNumber.length === 7){

    return creg.test(vehicleNumber);

  } else if(vehicleNumber.length === 8){

    return xreg.test(vehicleNumber);

  } else{

    return false;

  }

}

 
//判断数组中是否存在某个特定值
export function arrayhasValue(arr,value){
  var hasit = false;
  arr.forEach((item)=>{
    if(item === value){
      hasit = true;
    }
  })
  return hasit
}



export  function maptoArr (mapdata){
  var arr = []
  for (var index in mapdata) {
    arr.push([index,mapdata[index]])
  }
  return arr
}

//事件字符串转化成时间戳
export function toTimestamp (timestr) {
  var date = new Date(timestr)
  var str = date.getTime()/1000
  return str
}

export function isToday(timestr){
  var now = new Date()
  var year = now.getFullYear();
  var month = (now.getMonth()<=9)?'0'+(now.getMonth() + 1):(now.getMonth() + 1);
  var date = (now.getDate())<=9?'0'+now.getDate():now.getDate();
  var today = `${year}/${month}/${date}`;
  console.log('timestr'+timestr)
  console.log('today'+today)
  return timestr===today?true:false
}

export function getNow() {
  var now = new Date()
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
}


//时间戳转化成时间
export function formatDate(timestampnum) {
  var now = new Date(timestampnum*1000)
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  date = date <10 ?`0${date}`:date;
  month = month <10 ?`0${month}`:month;
  hour = hour <10 ?`0${hour}`:hour;
  minute = minute <10 ?`0${minute}`:minute;
  second = second <10 ?`0${second}`:second;
  return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
}


//时间戳转化成时间
export function getHMS(timestampnum) {
  var now = new Date(timestampnum*1000)
  // var year = now.getFullYear();
  // var month = now.getMonth() + 1;
  // var date = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  hour = hour <10 ?`0${hour}`:hour;
  minute = minute <10 ?`0${minute}`:minute;
  second = second <10 ?`0${second}`:second;
  return  hour + ":" + minute + ":" + second;
}

export function getCurHMS(){
  var d = new Date();
  // var dateKey = (d.getMonth()+1)+'-'+d.getDate()+' '+d.getHours()+':'+d.getMinutes()+':'+d.getSeconds();
  var hour = d.getHours();
  var minute = d.getMinutes();
  var second = d.getSeconds();
  hour = hour <10 ?`0${hour}`:hour;
  minute = minute <10 ?`0${minute}`:minute;
  second = second <10 ?`0${second}`:second;
  return  hour + ":" + minute + ":" + second;
}


//从minitask中获取站点arr
export function getStationarrFromMinitask (minitaskArr){
  if(Array.isArray(minitaskArr)){
    var stationArr = []
    minitaskArr.forEach((item,key)=>{
      stationArr.push(item.end_name)
    })
    return stationArr;
  }else{
    return []
  }
}


/**
 * 计算两个坐标点的角度
 * @param {Object} firstPoint 第一个经纬度坐标点
 * @param {Object} nextPoint 下一个经纬度坐标点
 * @returns {Number} 角度值
 */
export function getAngle(firstPoint, nextPoint) {
  if (!(firstPoint && nextPoint)) {
      return 0;
  }

  let dRotateAngle = Math.atan2(
      Math.abs(firstPoint.lng - nextPoint.lng),
      Math.abs(firstPoint.lat - nextPoint.lat)
  );
  if (nextPoint.lng >= firstPoint.lng) {
      if (nextPoint.lat >= firstPoint.lat) {
      } else {
          dRotateAngle = Math.PI - dRotateAngle;
      }
  } else {
      if (nextPoint.lat >= firstPoint.lat) {
          dRotateAngle = 2 * Math.PI - dRotateAngle;
      } else {
          dRotateAngle = Math.PI + dRotateAngle;
      }
  }
  dRotateAngle = (dRotateAngle * 180) / Math.PI;
  return dRotateAngle;
}



// ws车辆状态转化，checkState和drivingState 转化为五种标准状态
  export const exchangeStatus = (checkState,drivingState) =>{
    if(drivingState === 0) {
      return drivingState
    } else {
     return checkState
    }
  }

export const getPagenum = (total,size) => {
  return Math.ceil(total/size) //向上取整
}

// 输入框去除前中后空格
export const  getValueFromEvent = (e)=>{
      return e.target.value.replace(/(^\s*)|(\s*$)/g, '');
}

// 把空格转义成nbsp

export const stringNbsp = (str) =>{
  str=str.replace(/( )/g,"&nbsp;")
  return str
}
/**
 * @description   : 处理数据
 * @param          {Number} val 需要改变的数据
 * @param          {Number} num 保留的位数
 * @param          {Number} maxNum 最大显示数据处理
 * @return         {Number}
 */
export const toFixedNum = (val, num, maxNum) => {
  if(maxNum && val > maxNum){
    val = maxNum;
    return val;
  }
  if(+val !== 0){
    val = val.toFixed(num);
  }
  return val;
}
