export const initState = () => {
    return {
        chartData: {
            speedChart: {
                // 左后轮速度
                backLeftWheelSpeedFeedback: null,
                // 右后轮速度
                backRightWheelSpeedFeedback: null,
                // 当前车速
                speedFeedback: null
            },

            mileageChart: {
                // 累计里程
                odometer: null,
                // 小记历程
                tripmeter: null
            },

            batteryChart: {
                // 电量
                batterySoc: null,
                // 充电状态
                chargeStatus: null,
                // 电机输出电压
                chargerOutputVoltage: null,
                // 电机输出电流
                chargerOutputCurrent: null
            }
        },

        carData: {
            // 车辆模式
            chassisControlModeCode:null,
            // 档位
            gearStatus: null,
            // 四个轮子的胎压
            leftFrontTirePressure: null,
            rightFrontTirePressure: null,
            leftRearTirePressure: null,
            rightRearTirePressure: null,
            // 四个轮子的胎温
            leftFrontTireTemperature: null,
            rightFrontTireTemperature: null,
            leftRearTireTemperature: null,
            rightRearTireTemperature: null
        },

        hardWareMesage: {
            // 请求数据 - start
            // 制动压力
            brakePressureCmd: null,
            // 转向角度
            steeringDegreeCmd: null,
            // 电机转速
            motorRpmCmd: null,
            // 请求数据 - end

            // 返回数据 - start
            // 制动压力
            brakePressureFeedback: null,
            // 转向角度
            steeringDegreeFeedback: null,
            // 电机转速
            motorRpmFeedback: null
            // 返回数据 - end
        },

        // 错误信息列表
        errorCodes: null,

        // 接收时间
        resiveTime: null
    }
}