export const namespace = 'task';

export default function task (state = {}, action) {
  switch (action.type) {
    case `${namespace}/request`:
      return {
        ...state,
        pending: true
      };
    case `${namespace}/success`:
      return {
        ...state,
        pending: false,
        data:  action.payload
      }
    case `${namespace}/add_success`:
      return {
        ...state,
        pending:false,
        data:action.payload
    }
    case `${namespace}/datanull`:
      return {
        ...state,
        pending:false,
        data:action.payload
    }

    case `${namespace}/cartasklist`:
    return {
      ...state,
      pending: false,
      cartasklist:  action.payload
    }

    case `${namespace}/taskaddsuccess`:
      return {
        ...state,
        pending: false,
        message:action.message
      }
    default:
      return state;
  }
}
