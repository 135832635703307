export const namespace = 'global';
export const menuData = [
  // { name: '视频监控', icon: 'area-chart', path: 'overview' },
  // { name: '园区管理', icon: 'layout', path: 'district' },
  // { name: '车辆管理', icon: 'car', path: 'car',children:[{name:'车辆管理', path: 'list'},{name:'车辆软件', path: 'software'}] },
  // { name: '任务管理', icon: 'mail', path: 'task' },
  // { name: '软件管理', icon: 'appstore', path: 'software' },
  // { name: '个人中心', icon: 'user', path: 'user' },
  // { name: '用户管理', icon: 'user', path: 'manage',children:[{name:'用户', path: 'user'},{name:'角色', path: 'role'},{name:'菜单', path: 'url'}] }
];

const initialState = {
  menuData,
  collapsed: false,
  topmenue:[]
};

export default function global (state = initialState, action) {
  switch (action.type) {
    case `${namespace}/changeLayoutCollapsed`:
      return {
        ...state,
        collapsed: action.payload
      };
      case `${namespace}/success`:
        console.log(action)
        return {
          ...state,
          menuData: action.payload
        };
      case `${namespace}/topmenue`:
        console.log('uiuiui')
        console.log(action.payload)
        const tempArr = state.topmenue;
        if(state.topmenue.length === 0){
          tempArr.push(action.payload)
        }else{
          var idArr = state.topmenue.map((item)=>{
            return item.record_id;
          })
          console.log(idArr)
          console.log(idArr.indexOf(action.payload.record_id))
          if(idArr.indexOf(action.payload.record_id)===-1){
            tempArr.push(action.payload)
          }
        }
        return {
          ...state,
          topmenue:tempArr
        }
      case `${namespace}/deletetopmenu`:
          console.log('delete')
          console.log(action.payload)
          const tempdata = state.topmenue;
          state.topmenue.forEach((item,index)=>{
            if(item.path === action.payload){
              tempdata.splice(index,1)
            }
          })
          return {
            ...state,
            topmenue:tempdata
          }
    default:
      return state;
  }
}
