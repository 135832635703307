import mqtt from "mqtt";
import { takeLatest } from 'redux-saga/effects';
import { Utf8ArrayToStr } from '../utils/utils'
import config from '../utils/config.js';
import { getUserinfo, getAuthority } from '../utils/authority'
const protobuf = require("protobufjs");
const { mqttHost: host } = config

const username = getUserinfo()
const token = getAuthority() ? getAuthority().split(" ")[1] : "";
let mqttClient = null;
let _mydispatch = null;
let connectUrl;

var jsonDescriptor = require("./msg.json"); // exemplary for node
var root = protobuf.Root.fromJSON(jsonDescriptor);

function areamqttconnect({ mydispatch, id }) {
    connectUrl = `/udi/client/10/${id}/#`;
    _mydispatch = mydispatch
    const connectConfig = {
        clientId: `udi-web-${username}-uuid`,
        clear: true,
        username,
        password: token,
        host
    }
    try {
        const { host } = connectConfig
        mqttClient = mqtt.connect(host, connectConfig)
    } catch (e) {
        console.error('mqtt连接异常', e);
    }
    mqttClient.on("connect", () => {
        console.log("mqtt 建立连接成功");
        mydispatch({ type: 'areawebsocket/success' });
        mqttClient.subscribe(connectUrl, (error, res) => {
            if (error) {
                console.log("订阅主题错误", error);
                return;
            }
            console.log("订阅主题", res);
        });
    });
    mqttClient.on("error", (error) => {
        console.log("MQTT 建立连接失败", error);
        mydispatch({ type: 'areawebsocket/fall' });
    });
    mqttClient.on("message", (topic, buf) => {
        const messageFile = root.lookupType("Web.Message");
        const { classId } = messageFile.decode(buf);
        switch (classId) {
            case 6:
                //车辆状态消息
                var carMessage = root.lookupType("Web.StateReport");
                var carStatus = carMessage.decode(buf);
                carStatus.carId = Utf8ArrayToStr(carStatus.carId);
                if (typeof carStatus.payload.localTaskId != 'undefined') {
                    var local_taskidArray = carStatus.payload.localTaskId;
                    carStatus.payload.localTaskId = Utf8ArrayToStr(local_taskidArray);
                }
                if (typeof carStatus.payload.platformTaskId != 'undefined') {
                    var platform_taskidArray = carStatus.payload.platformTaskId;
                    carStatus.payload.platformTaskId = Utf8ArrayToStr(platform_taskidArray);
                }

                if (carStatus != null) {
                    if (isNaN(!carStatus.payload.longitude) && !isNaN(carStatus.payload.longitude)) {
                        _mydispatch({ type: 'area/carstatewebsocket', data: carStatus });//车辆状态消息
                        _mydispatch({ type: 'areawebsocket/carstatus', data: carStatus });//车辆状态消息
                    }
                }
                break;
            case 9:
                //调度任务开始通知
                /*         var beginMessage = root.lookupType("Web.StartTask");
                        var beginStatus = beginMessage.toObject(beginMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/taskstart',data:beginStatus});//调度任务开始通知
                break;
            case 13:
                /*    var plannedrouteMessage = root.lookupType("Web.PlannedRoute");
                   var beginStatus = plannedrouteMessage.toObject(plannedrouteMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/plannedroute',data:message});//路径规划通知
                break;
            case 11:
                /*       var endMessage = root.lookupType("Web.EndTask");
                      var endStatus = endMessage.toObject(endMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/taskend',data:endStatus});//任务结束通知
                break;
            // case 10:
            //     _mydispatch({ type : 'carwebsocket/returnmsg',data:message});//终止调度任务指令
            //     break;
            // case 8:
            //     _mydispatch({ type : 'carwebsocket/returnmsg',data:message});//下发调度任务指令
            //     break;
            case 15:
                /*    var takeoverMessage = root.lookupType("Web.TakeOverReport");
                   var takeOver = takeoverMessage.toObject(takeoverMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/takeover',data:takeOver});//接管操作
                break;
            case 7:
                /*               var takeoverMessage = root.lookupType("Web.HardwareWarn");
                              var takeOver = takeoverMessage.toObject(takeoverMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/hardwarewarn',data:message});//硬件行驶异常报告
                break;
            case 8:
                /*     var takeoverMessage = root.lookupType("Web.DrivingWarn");
                    var takeOver = takeoverMessage.toObject(takeoverMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/drivingwarn',data:message});//行驶异常告警
                break;
            case 20:
                var warnmsg = root.lookupType("Web.ErrWarn");
                var warnmsgData = warnmsg.decode(buf);//转化成object
                var errcodeArr = []
                warnmsgData.payload.nodes.forEach((item, key) => {
                    errcodeArr.push(item.code)
                })
                _mydispatch({ type: 'areawebsocket/errorcode', data: { classid: 20, carid: Utf8ArrayToStr(warnmsgData.carId), errcodeArr: errcodeArr } });
                break;
            default:
                break;
        }
    })
}

function carmqttclose() {
    try {
        mqttClient.end();
        mqttClient = null;
        console.log("断开成功");
        _mydispatch({ type: 'areawebsocket/clearerrorcode' });
    } catch (e) {
        console.log("断开异常", e);
    }
}

export function* watchAreamqtt() {
    yield takeLatest('AREA_MQTT_CONNECT', areamqttconnect);
}


export function* watchAreamqttclose() {
    yield takeLatest('AREA_MQTT_WSCLOSE', carmqttclose);
}


export default [watchAreamqtt, watchAreamqttclose]