import { takeLatest } from 'redux-saga/effects';
import { Utf8ArrayToStr } from '../utils/utils'
import config from '../utils/config.js';
import {getAuthority} from '../utils/authority'
var protobuf = require("protobufjs");

var ws = null;
var _mydispatch = null;


function* areawsconnect({mydispatch,username,id}) {
    var areawsParams = `${username}!0!${id}`;        
    const token =   getAuthority().substring(7);
    ws = new WebSocket(config.websocket,[token,areawsParams]);
    ws.binaryType = 'arraybuffer';

    _mydispatch = mydispatch;
    ws.onopen = () => {
        console.log('园区websocket:connected');
        _mydispatch({ type: 'areawebsocket/success' });
    };
    ws.onerror = e => {
        _mydispatch({ type: 'areawebsocket/fall' });
    };
    ws.onmessage = e => {
        var jsonDescriptor = require("./msg.json"); // exemplary for node
        var root = protobuf.Root.fromJSON(jsonDescriptor);
        var _AreaMessage = root.lookupType("Web.Message");
        var buf = new Uint8Array(e.data);
        var message = _AreaMessage.decode(buf);
        console.log(message.classId);
        switch (message.classId) {
            case 6:
                //车辆状态消息
                var carMessage = root.lookupType("Web.StateReport");
                var carStatus = carMessage.decode(buf);
                carStatus.carId = Utf8ArrayToStr(carStatus.carId);
                if (typeof carStatus.payload.localTaskId != 'undefined') {
                    var local_taskidArray = carStatus.payload.localTaskId;
                    carStatus.payload.localTaskId = Utf8ArrayToStr(local_taskidArray);
                }
                if (typeof carStatus.payload.platformTaskId != 'undefined') {
                    var platform_taskidArray = carStatus.payload.platformTaskId;
                    carStatus.payload.platformTaskId = Utf8ArrayToStr(platform_taskidArray);
                }

                if (carStatus != null) {
                    if (isNaN(!carStatus.payload.longitude) && !isNaN(carStatus.payload.longitude)) {
                        _mydispatch({ type: 'area/carstatewebsocket', data: carStatus });//车辆状态消息
                        _mydispatch({ type: 'areawebsocket/carstatus', data: carStatus });//车辆状态消息
                    }
                }
                break;
            case 9:
                //调度任务开始通知
        /*         var beginMessage = root.lookupType("Web.StartTask");
                var beginStatus = beginMessage.toObject(beginMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/taskstart',data:beginStatus});//调度任务开始通知
                break;
            case 13:
             /*    var plannedrouteMessage = root.lookupType("Web.PlannedRoute");
                var beginStatus = plannedrouteMessage.toObject(plannedrouteMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/plannedroute',data:message});//路径规划通知
                break;
            case 11:
          /*       var endMessage = root.lookupType("Web.EndTask");
                var endStatus = endMessage.toObject(endMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/taskend',data:endStatus});//任务结束通知
                break;
            // case 10:
            //     _mydispatch({ type : 'carwebsocket/returnmsg',data:message});//终止调度任务指令
            //     break;
            // case 8:
            //     _mydispatch({ type : 'carwebsocket/returnmsg',data:message});//下发调度任务指令
            //     break;
            case 15:
             /*    var takeoverMessage = root.lookupType("Web.TakeOverReport");
                var takeOver = takeoverMessage.toObject(takeoverMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/takeover',data:takeOver});//接管操作
                break;
            case 7:
  /*               var takeoverMessage = root.lookupType("Web.HardwareWarn");
                var takeOver = takeoverMessage.toObject(takeoverMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/hardwarewarn',data:message});//硬件行驶异常报告
                break;
            case 8:
            /*     var takeoverMessage = root.lookupType("Web.DrivingWarn");
                var takeOver = takeoverMessage.toObject(takeoverMessage.decode(buf));//转化成object */
                //_mydispatch({ type : 'areawebsocket/drivingwarn',data:message});//行驶异常告警
                break;
            case 20:
                var warnmsg = root.lookupType("Web.ErrWarn");
                var warnmsgData = warnmsg.decode(buf);//转化成object
                var errcodeArr = []
                warnmsgData.payload.nodes.forEach((item, key) => {
                    errcodeArr.push(item.code)
                })
                _mydispatch({ type: 'areawebsocket/errorcode', data: { classid: 20, carid: Utf8ArrayToStr(warnmsgData.carId), errcodeArr: errcodeArr } });
                break;
            default:
                break;
        }

    };
    ws.onclose = e => {
        console.log('园区websocket:disconnected');
        _mydispatch({ type: 'areawebsocket/fall' });
    };
    yield ;
}


function* areawsclose() {
    ws.close();
    yield _mydispatch({ type: 'areawebsocket/clearerrorcode' });
}




export function* watchAreaws() {
    yield takeLatest('AREA_WSCONNECT', areawsconnect);
}

export function* watchAreawsclose() {
    yield takeLatest('AREA_WSCLOSE', areawsclose);
}


export default [
    watchAreaws,
    watchAreawsclose
];
