export const namespace = 'warnmanage';
const initstate ={
  alarmlist:[],
  blacklist:[]
}
export default function warnmanage (state = {initstate}, action) {
  switch (action.type) {
    case `${namespace}/alarmlist`:
      return {
        ...state,
        pending: true
      };
    case `${namespace}/blacklist`:
      return {
        ...state,
        blacklist:action.payload
      }
    default:
      return state;
  }
}
