import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../utils/request';
import { push } from 'react-router-redux';
export function* fetch ({ id,callback, ...params }) {
  const uri = '/api/v1/pub/current/user';
  const data = yield call(request, uri, {
    method: 'GET'
  });
  if(callback) {callback(data.Data)};
  yield put({
    type: 'user/success',
    payload: data
  });
}


export function* polish({ type, username, password, ...attrs }){
  try {
    yield put({ type: 'user/request' });
    yield call(request, '/user_info', {
      method: 'POST',
      body: {
        username,
        password,
        email:attrs.email,
        phone: attrs.phone,
        code: attrs.code
      }
    });
    yield put({ type: 'user/success' });
    yield put(push({
      pathname: '/user',
      state: { account: username}
    }));
  } catch (error) {
    yield put({
      type: 'user/failed',
      payload: error.message
    });
  }


}


export function* watchFetchUserinfo () {
  yield takeLatest('USER_INFO', fetch);
}

export function* watchPolishUserinfo(){
  yield takeLatest('USER_POLISH',polish);
}

export default [
    watchFetchUserinfo,
    watchPolishUserinfo
];
