import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import Loadable from 'react-loadable';
import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/es/locale-provider/zh_CN';
import Authorized from './utils/Authorized';
import routerConfig from './routerConfig';
import NotFound from './routes/Exception/404';

const routerData = {};

const dynamicWrapper = (component, layout) => {
  // () => require('module')
  if (component.toString().indexOf('.then(') < 0) {
    return props => layout()
      ? React.createElement(
        layout().default,
        { ...props, routerData },
        React.createElement(component().default, props)
      )
      : React.createElement(component().default, { ...props, routerData });
  }

  // () => import('module')
  return Loadable({
    loader: () => {
      return layout
        ? Promise.all([component(), layout()]).then(([rawComponent, rawLayout]) => {
          const Layout = rawLayout.default || rawLayout;
          const Component = rawComponent.default || rawComponent;
          return props => React.createElement(
            Layout,
            { ...props, routerData },
            React.createElement(Component, props)
          );
        })
        : component().then(raw => {
          const Component = raw.default || raw;
          return props => React.createElement(Component, { ...props, routerData });
        });
    },
    loading: () => {
      return <Spin size='large' className='global-spin' />;
    }
  });
};

/**
 * 将路由信息扁平化，继承上一级路由的 path
 * @param {Array} config 路由配置
 */
function recursiveRouterConfigV4(config = []) {
  const routeMap = [];
  config.forEach(({ children, ...route }) => {
    if (Array.isArray(children)) {
      route.childRoutes = recursiveRouterConfigV4(children);
    }
    routeMap.push(route);
  });

  return routeMap;
}

/**
 * 将扁平化后的路由信息生成 Route 节点
 *
 * @param {Element} container 路由容器
 * @param {object} router 路由对象
 * @param {string} contextPath 上层路由地址
 * @return {Route}
 */
function renderRouterConfigV4(container, routes, contextPath) {
  const routeChildren = [];
  const routeRedirect = [];
  const renderRoute = (routeContainer, routeItem, routeContextPath) => {
    let routePath;
    if (!routeItem.path) {
      console.error('route must hast `path`');
    } else if (routeItem.path === '/' || routeItem.path === '*') {
      routePath = routeItem.path;
    } else {
      routePath = `/${routeContextPath}/${routeItem.path}`.replace(/\/+/g, '/');
    }

    // 若存在子路由。递归当前路径，并添加到路由中
    if (Array.isArray(routeItem.childRoutes) && routeItem.childRoutes.length > 0) {
      const to = routeItem.redirect
        ? routeItem.redirect
        : `${routePath}/${routeItem.childRoutes[0].path}`.replace(/\/+/g, '/');
      routeRedirect.push(
        <Redirect key={routePath} exact from={routePath} to={to} />
      );
      routeItem.childRoutes.forEach((r) => {
        // 递归传递当前 route.component 作为子节点的 container
        renderRoute(routeItem.component || routeContainer, r, routePath);
      });
    } else if (routeItem.layout && routeItem.component) {
      routeChildren.push(
        <Authorized.AuthorizedRoute
          key={routePath}
          exact
          path={routePath}
          authority={routeItem.authority}
          redirectPath={routeItem.exception}
          component={dynamicWrapper(routeItem.component, routeItem.layout)}
        />
      );
    } else if (routeContainer && routeItem.component) {
      routeChildren.push(
        <Authorized.AuthorizedRoute
          key={routePath}
          exact
          path={routePath}
          authority={routeItem.authority}
          redirectPath={routeItem.exception}
          component={dynamicWrapper(routeItem.component, routeContainer)}
        />
      );
    } else {
      routeChildren.push(
        <Authorized.AuthorizedRoute
          key={routePath}
          exact
          path={routePath}
          authority={routeItem.authority}
          redirectPath={routeItem.exception}
          component={dynamicWrapper(routeItem.component, null)}
        />
      );
    }
  }

  routes.forEach((r) => {
    renderRoute(container, r, contextPath);
  });

  // 路由没找到页面
  routeChildren.push(<Route key='/404' render={NotFound} />)
  return routeRedirect.concat(routeChildren);
}

/**
 * 根据路由配置生成完整路由节点
 *
 * @param {object} history 浏览器 history
 * @return {Router}
 */
function RouterConfig (history) {
  const routesWithReactRouter4 = recursiveRouterConfigV4(routerConfig);
  const routeChildren = renderRouterConfigV4(() => null, routesWithReactRouter4, '/');
  return (
    <ConfigProvider locale={zhCN}>
      <ConnectedRouter history={history}>
        <Switch>{routeChildren}</Switch>
      </ConnectedRouter>
    </ConfigProvider>
  );
}

export default RouterConfig;
