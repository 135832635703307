export const namespace = 'user';

export default function user (state = {}, action) {
  switch (action.type) {
    case `${namespace}/request`:
      return {
        ...state,
        pending: true
      };
    case `${namespace}/success`:
      return {
        ...state,
        pending: false,
       data:  action.payload
      }
    default:
      return state;
  }
}
