import { takeLatest, call, put,fork } from 'redux-saga/effects';
import request from '../utils/request';
import {Utf8ArrayToStr} from '../utils/utils';
import config from '../utils/config.js';
import {getAuthority} from '../utils/authority'
import {getCurHMS} from '../utils/utils'

var protobuf = require("protobufjs");

var ws = null; // 缓存 websocket连接
var _mydispatch = null; // 这个变量是因为saga无法支持callback 只能变通处理（这也是个坑点）
var protpfile = null; // 缓存proto文件



 function*  carwsconnect({mydispatch,id,username}) {
    var carwsParams = `${username}!1!${id}`;
    const token =   getAuthority().substring(7);
    ws = new WebSocket(config.websocket,[token,carwsParams]);
    ws.binaryType = 'arraybuffer';
    _mydispatch = mydispatch;
    ws.onopen = () => {
        console.log('小车websocket:connect');
        _mydispatch({type:'carwebsocket/success'});
        /* var dateKey =   getCurHMS(); */
        // _mydispatch({ type : 'carwebsocket/messages',data:{classid:0,time:dateKey,msg:'车辆连接'}});
    };
    ws.onerror = e => {
        _mydispatch({type:'carwebsocket/fall'});
    };
    ws.onmessage = e => {
        // console.log(e)
        var jsonDescriptor = require("./msg.json");
        var root = protobuf.Root.fromJSON(jsonDescriptor);
        var messageFile = root.lookupType("Web.Message");
        var buf = new Uint8Array(e.data);
        var message = messageFile.decode(buf);
        console.log(message.classId);
        var dateKey = getCurHMS()
        switch(message.classId){
            case 6:
                var carMessage = root.lookupType("Web.StateReport");
                var carStatus = carMessage.decode(buf);
                console.log(carStatus)
                carStatus.payload.localTaskId = Utf8ArrayToStr(carStatus.payload.localTaskId);
                carStatus.payload.platformTaskId = Utf8ArrayToStr(carStatus.payload.platformTaskId);
                carStatus.carId = Utf8ArrayToStr(carStatus.carId);
                // console.log(carStatus.payload)
                _mydispatch({ type : 'carwebsocket/carstatus',data:carStatus.payload});//车辆状态消息

                if(carStatus.payload.longitude&&carStatus.payload.latitude){
                    _mydispatch({ type : 'carwebsocket/realtimecargps',data:[carStatus.payload.longitude,carStatus.payload.latitude]});//车辆状态消息
                }
                //_mydispatch({ type : 'carwebsocket/messages',data:{classid:6,time:dateKey,msg:'车辆正常状态消息'}});//车辆状态消息
                break;
            case 9:
                //调度任务开始通知startTime
                var beginMessage = root.lookupType("Web.StartTask");
                var beginStatus = beginMessage.decode(buf);//转化成object
                console.log(beginStatus);
                _mydispatch({ type : 'carwebsocket/taskstart',data:beginStatus.payload});//调度任务开始通知;传给reducer的数据格式是obj
                _mydispatch({ type : 'carwebsocket/messages',data:{classid:9,time:dateKey,msg:'任务开始通知'}});
                break;
            case 13:
                var plannedrouteMessage = root.lookupType("Web.PlannedRoute");
                var pathplanStatus =plannedrouteMessage.decode(buf);//转化成object
                console.log('路径规划通知');

                console.log(pathplanStatus);
                _mydispatch({ type : 'carwebsocket/plannedWarning',data:1});//路径规划通知
                _mydispatch({ type : 'carwebsocket/messages',data:{classid:13,time:dateKey,msg:'规划路径通知'}});
                break;
            case 11:
               /*  var endMessage = root.lookupType("Web.EndTask"); */
                //var endBuf = new Uint8Array(message.Payload);
                /* var endStatus = endMessage.decode(buf);//转化成object */
                console.log('任务结束通知');
                _mydispatch({ type : 'carwebsocket/taskend',data:1});//任务结束通知endTime
                _mydispatch({ type : 'carwebsocket/messages',data:{classid:11,time:dateKey,msg:'任务结束通知'}});
                break;
            // case 10:
            //     _mydispatch({ type : 'carwebsocket/returnmsg',data:message});//终止调度任务指令
            //     break;
            // case 8:
            //     _mydispatch({ type : 'carwebsocket/returnmsg',data:message});//下发调度任务指令
            //     break;
            case 15:
                var takeoverMessage = root.lookupType("Web.TakeOverReport");
                //var takeoverBuf = new Uint8Array(message.Payload);
                console.log(takeoverMessage)
                // var takeOver = takeoverMessage.decode(buf);//转化成object
                // console.log(takeOver);
                // if(Utf8ArrayToStr(takeOver.payload.takeOverType)==0){_mydispatch({ type : 'carwebsocket/messages',data:{classid:15,time:dateKey,msg:'车辆开始接管'}});}
                // if(Utf8ArrayToStr(takeOver.payload.takeOverType)==1){_mydispatch({ type : 'carwebsocket/messages',data:{classid:15,time:dateKey,msg:'车辆结束接管'}});}

                //_mydispatch({ type : 'carwebsocket/takeover',data:takeOver});//接管操作
                break;
            case 7:
                /* var hardwareWarnMessage = root.lookupType("Web.HardwareWarn");
                var hardwareWarn = hardwareWarnMessage.decode(buf);//转化成object */
                // console.log(hardwareWarn);
                _mydispatch({ type : 'carwebsocket/messages',data:{classid:7,time:dateKey,msg:'硬件异常报告'}});
                //_mydispatch({ type : 'carwebsocket/hardwarewarn',data:message});//硬件行驶异常报告
                break;
            case 8:
               /*  var drivingWarnMessage = root.lookupType("Web.DrivingWarn");
                var drivingWarn = drivingWarnMessage.decode(buf);//转化成object */
                // console.log(drivingWarn);
                _mydispatch({ type : 'carwebsocket/messages',data:{classid:8,time:dateKey,msg:'行驶异常告警'}});
                //_mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
                break;
            

            case 20:
                // console.log('任务结束通知')
                var warnmsg = root.lookupType("Web.ErrWarn");
                var warnmsgData = warnmsg.decode(buf);//转化成object
                // console.log(warnmsgData)
                // console.log('-------------------------')
                var errcodeArr = []
                warnmsgData.payload.nodes.forEach((item, key) => {
                    errcodeArr.push(item)
                })
                // console.log(errcodeArr)
                // warnmsgData.payload.nodes.forEach((item,key)=>{
                //     key === 0 ? data = item.code : data = data + '、' + item.code
                // })
                //_mydispatch({ type : 'carwebsocket/messages',data:{classid:20,time:dateKey,msg:data}});

                _mydispatch({ type: 'carwebsocket/errorcode', data: { classid: 20, errcodeArr: errcodeArr } });
                break;
            case 29:
                var PresetPositionmsg = root.lookupType("Web.PresetPosition");
                var PresetPositionmsgData = PresetPositionmsg.decode(buf);//转化成object
                
                console.log('收到29消息=============================')
                console.log(PresetPositionmsgData)
               
                _mydispatch({ type: 'carwebsocket/presetposition'});
                break;
            case 30:
                var Cruisemsg = root.lookupType("Web.Cruise");
                var CruisemsgData = Cruisemsg.decode(buf);//转化成object
                
                console.log('收到30消息=============================')
                console.log(CruisemsgData)
                
                _mydispatch({ type: 'carwebsocket/cruisemsg'});
                break;
            case 36:
                //一键求助报警上报
                var AlarmMenuReportMessage = root.lookupType("Web.AlarmMenuReport");
                var AlarmMenuReport = AlarmMenuReportMessage.decode(buf);//转化成object
                console.log(AlarmMenuReport);
                console.log('--------------36---------------------')
                _mydispatch({ type : 'carwebsocket/messages',data:{classid:35,time:dateKey,msg:'一键报警!'}});
                _mydispatch({ type : 'carwebsocket/blacklpncmd',data:AlarmMenuReport.payload});
                //_mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
                break;
    
            // case 37:
            //     //黑名单车牌号报警上报
            //     var AlarmBlackLpnReportMessage = root.lookupType("Web.AlarmBlackLpnReport");
            //     var AlarmBlackLpnReport = AlarmBlackLpnReportMessage.decode(buf);//转化成object
            //     console.log(AlarmBlackLpnReport);
            //     console.log('--------------37---------------------')

            //     // _mydispatch({ type : 'carwebsocket/blacklpncmd',data:{classid:37,data:BlackLpnCmd}});
            //     //_mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
            //     break;
                
            case 41:
                //抓拍图片通知前端  车牌号为1， 人脸为2，抓拍为3
                var CaptureNotifyMessage = root.lookupType("Web.CaptureNotify");
                var CaptureNotify = CaptureNotifyMessage.decode(buf);//转化成object
                console.log('--------------41---------------------')
                
                console.log(CaptureNotify);
                CaptureNotify.payload.name = Utf8ArrayToStr(CaptureNotify.payload.name)
                CaptureNotify.payload.object = Utf8ArrayToStr(CaptureNotify.payload.object)
                _mydispatch({type:'carwebsocket/capturenotify',payload:CaptureNotify.payload})

                
                // _mydispatch({ type : 'carwebsocket/blacklpncmd',data:{classid:37,data:BlackLpnCmd}});
                //_mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
                break;
            default:        
                break;
        }
    };
    ws.onclose = (e) => {
        console.log('小车websocket:disconnect');
        // console.log(e)
        _mydispatch({ type : 'carwebsocket/messages',data:{classid:0,time:'车辆断开连接通知',msg:'小车websocket断开'}});
        _mydispatch({type:'carwebsocket/fall'});
        // _mydispatch({type:'CAR_WSCONNECT',mydispatch:_mydispatch,username:getUserinfo()})

    };
    yield ;
}


function* carwsclose(mydispatch) {
    yield ws.close();
}


function* sendmsg(msg){
    console.log(msg)
    try{
        yield fork(decodeencodewithproto,msg);
    }catch(err) {
        console.log(err);
    }
}


function* decodeencodewithproto(msg) {
    let restroot ;
    if(protpfile == null){
        // 缓存proto 对象
        restroot = yield call(protobuffun);
        protpfile = restroot;
    }else{
        restroot = protpfile;
    }

    console.log(msg)
    switch (msg.msgtype){
        case 'cloundmsg':
            var ManualControl = restroot.lookupType("Web.ManualControl");
            const tempdata = {
                classId:28,
                payload:{
                   ...msg.data
                }
            }
            console.log(tempdata)
            var errMsg = ManualControl.verify(tempdata);
            if (errMsg)
                throw Error(errMsg);
            var message = ManualControl.create(tempdata);
            console.log(message)
            // console.log( ManualControl.encode(message))
            var buffer = ManualControl.encode(message).finish();
            // console.log(buffer)
            // console.log(Utf8ArrayToStr(buffer))
            console.log(buffer)
            ws.send(buffer);
            // console.log(msg)
            break;
        // case 'continueCar':
        //     break;
        // case 'whistle':
        //     break;
        default:

    }

    // var Area = restroot.lookupType("Web.Area");
    // var payload = { data: msg };
    // var errMsg = Area.verify(payload);
    // if (errMsg)
    //     throw Error(errMsg);
    // var message = Area.create(payload);
    // var buffer = Area.encode(message).finish();
    // ws.send(buffer);

}


function protobuffun() {
    return new Promise(resolve => {
        var jsonDescriptor = require("./msg.json");
        var root = protobuf.Root.fromJSON(jsonDescriptor);
        resolve(root);
    })
}



//获取这个任务的小车实时已经走过的路径
export function* realtimegps ({carId,PlatformTaskId,callback}) {
    try {
      const uri = '/api/m1/cargps/'+carId+'/?PlatformTaskId='+PlatformTaskId;
      const data = yield call(request, uri, {
        method: 'GET'
      });
      if(data.GPS != null){

        //格式转化;统一格式为[[],[],[]]的二维数组
        var gpsCon = [];
        var num = 0;
        for(var j in data['GPS']){
            gpsCon[num] = [data['GPS'][j].Longitude, data['GPS'][j].Latitude]
            num++;
        }

        if(callback) {callback(gpsCon)};
        yield put({
          type: 'carwebsocket/cargps',
          payload: gpsCon
        });
      }
    } catch (error) {
      console.log(error);
    }
  }



export function* watchCarws () {
  yield takeLatest('CAR_WSCONNECT', carwsconnect);
}

export function* watchCarwsclose () {
    yield takeLatest('CAR_WSCLOSE', carwsclose);
}

export function* watchCarsendmsg () {
    yield takeLatest('CAR_WSSEND', sendmsg);
}



export function* watchRealtimegps () {
    yield takeLatest('REALTIME_GPS', realtimegps);
  }



  

export default [
    watchCarws,
    watchCarwsclose,
    watchCarsendmsg,
    watchRealtimegps
];
