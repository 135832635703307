export const namespace = 'car';

export default function user (state = {unbindCarIdList:[]}, action) {
  switch (action.type) {
    case `${namespace}/request`:
      return {
        ...state,
        pending: true
      };
    case `${namespace}/success`:
      return {
        ...state,
        pending: false,
        data:  action.payload
      }
    case `${namespace}/datanull`:
    return {
      ...state,
      pending:false,
      data:action.payload
    }
    case `${namespace}/unbindcarlist`:
    return {
      ...state,
      pending:false,
      unbindCarIdList:action.payload
    }
    case `${namespace}/software`:
      return {
        ...state,
        softwarelist: action.payload
      };
    case `${namespace}/presetpos`:
      return {
        ...state,
        presetposlist: action.payload
      };
    case `${namespace}/cruiselist`:
      return {
        ...state,
        cruiselist: action.payload
      };
      
    default:
      return state;
  }
}
