export const namespace = 'area';

export default function area (state = {}, action) {
  switch (action.type) {
    case `${namespace}/request`:
      return {
        ...state,
        pending: true
      };
    case `${namespace}/success`:
      return {
        ...state,
        pending: false,
        data:  action.payload
      }
    case `${namespace}/add_success`:
      return {
        ...state,
        pending:false,
        data:action.payload
    }
    case `${namespace}/add_failed`:
      return {
        ...state,
        pending:false,
        status: 'error',
        data:action.payload
    }
    case `${namespace}/delete_success`:
      return {
        ...state,
        pending:false,
        data:action.payload
    }


    case `${namespace}/carstate`:
      return {
        ...state,
        pending:false,
        states:action.payload
    }

    case `${namespace}/loopstations`:
      return {
        ...state,
        pending:false,
        loopStationsList:action.payload
      }

      case `${namespace}/route`:
        return {
          ...state,
          pending:false,
          route:action.payload
        }

    case `${namespace}/carstatewebsocket`:
    if(typeof state.states != 'undefined'&&state.states != null){
      state.states.map((item) => {
        if(typeof item.Car != 'undefined'){
          if(item.Car.Id === action.data.carId){
            item.State.GPS = {Longitude:action.data.payload.longitude,Latitude:action.data.payload.latitude}
            item.State.Status = action.data.payload.status;
            item.State.Speed = action.data.payload.speed;
            item.State.Battery = action.data.payload.battery;
          }       
        }
        return {};
      });
    }
     
      
    return {
      ...state,
      pending:false,
  }
    default:
      return state;
  }
}
