import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../utils/request';
import con from '../utils/config'

const {m1} = con


export function* fetch ({page,size,area_name,status,createdby,carid,callback}) {
  try {
    const uri = m1+ '/bus/task/list';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        page,
        size,
        area_name,
        status,
        createdby,
        carid
      }
    });
    if (callback){callback(data.Data)}

    if(data.Data != null){
      yield put({
        type: 'task/success',
        payload: data['Data']
      });
    }

  } catch (error) {
    console.log(error);
  }
}

export function* newTaskList({area_name='all',carid='all',status='all',size,page,callback}){
  try{
    const uri = `${m1}/mission/task/${area_name}/${carid}/${status}/${size}/${page}`
    const data = yield call(request, uri,{
      method:"GET"
    })
    console.log(12321,data);
    if (callback){callback(data.Data)}
    if(data.Data != null){
      yield put({
        type: 'task/success',
        payload: data['Data']
      });
    }
  }
  catch(e){
    console.log(e);
  }

}



export function* newTaskAdd({submitForm,callback}){
  try{
    const uri = `${m1}/mission`
    const data = yield call(request, uri,{
      method:"POST",
      body:{...submitForm}
    })
    console.log(data);
    if (callback){callback(data.Data)}
  }catch(err){
    callback({err})
  }
}


export function* taskexe({carid,callback}){
  try {
    const uri = m1+ '/bus/task/list';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        page:1,
        size:10,
        status:'Executing',
        carid
      }
    });
    if (callback){callback(data.Data)}

  } catch (error) {
    console.log(error);
  }
}
export function* tasktest({id,callback}){
  try {
    const uri = m1+ '/bus/?id='+id;
    const data = yield call(request, uri, {
      method: 'GET',

    });
    if (callback){callback(data.Data)}

  } catch (error) {
    console.log(error);
  }
}
export function* taskdetails ({id,callback}) {
  try {
    const uri = m1+ '/bus/task?id='+id;
    const data = yield call(request, uri, {
      method: 'GET'
    });
    if (callback){callback(data.Data)}
  } catch (error) {
    console.log(error);
  }
}



/**
 * combined search
 */
export function* search ({AreaName,CarID,Status,CreatedBy}) {
  const body = {};
  if(AreaName != null){
    body.AreaName = AreaName;
  }
  if(CarID != null){
    CarID = CarID.toString();
    body.CarID = CarID;
  }
  if(Status != null){
    body.Status = Status;
  }
  if(CreatedBy != null){
    body.CreatedBy = CreatedBy;
  }

  try {
    const uri = m1+ '/task/query';
    const data = yield call(request, uri, {
      method: 'POST',
      body
    });
    if(data.Data != null){
      yield put({
        type: 'task/success',
        payload: data['Data']
      });
    }else{
      yield put({
        type: 'task/datanull',
        payload: null
      });
    }

  } catch (error) {
    console.log(error);
  }
}

/**
 * search according to creator
 */
export function* searchcreateby ({CreatedBy}) {
  try {
    const uri = m1+ '/task/query';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        CreatedBy
      }
    });
    if(data.Data != null){
      yield put({
        type: 'task/success',
        payload: data['Data']
      });
    }else{
      yield put({
        type: 'task/datanull',
        payload: null
      });
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * search according to AreaName
 */
export function* searchdistrict ({AreaName}) {
  AreaName = AreaName.toString();
  try {
    const uri = m1+ '/task/query';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        AreaName,
      }
    });
    if(data.Data != null){
      yield put({
        type: 'task/success',
        payload: data['Data']
      });
    }else{
      yield put({
        type: 'task/datanull',
        payload: null
      });
    }
  } catch (error) {
    console.log(error);
  }
}


/**
 * search according to CarID
 */
export function* searchcar ({CarID,AreaName}) {
  CarID = CarID.toString();
  AreaName = AreaName.toString();
  try {
    const uri = m1+ '/task/query';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        CarID,
        AreaName
      }
    });
    if(data.Data != null){
      yield put({
        type: 'task/success',
        payload: data['Data']
      });
    }else{
      yield put({
        type: 'task/datanull',
        payload: null
      });
    }
  } catch (error) {
    console.log(error);
  }
}


/**
 * search according to task status
 */

export function* searchstatus ({Status}) {

  try {
    const uri = m1+ '/task/query';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        Status,
      }
    });
    if(data.Data != null){
      yield put({
        type: 'task/success',
        payload: data['Data']
      });
    }else{
      yield put({
        type: 'task/datanull',
        payload: null
      });
    }
  } catch (error) {
    console.log(error);
  }
}




export function* taskadd ({area_name,station_ids,stop_time,carid,callback}) {
  try {

    const uri = m1+ '/bus/task?type=0';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        area_name,
        station_ids,
        stop_time,
        carid
      }
    });
    if(callback) callback(data);
    if(data.Status === 1){
      yield put({
        type: 'task/taskaddsuccess',
        message: 1
      });
      yield fetch({page:1,size:10});
    }

  } catch (error) {
    console.log(error);
  }
}



export function* cartasklist ({carID,callback}) {
  try {
    const uri = m1+ '/task/query/uftask';
    const data = yield call(request, uri, {
      method: 'POST',
      body:{
        carID
      }
    });
    if(callback) callback(data['Data']);
    if(data.Data != null){
      yield put({
        type: 'task/cartasklist',
        payload: data['Data']
      });
    }else{
      yield put({
        type: 'task/cartasklist',
        payload: null
      });
    }

  } catch (error) {
    console.log(error);
  }
}


export function* taskbegin ({id,callback}) {
  try {
    const uri = m1+ '/bus/start/?id='+id;
    const data = yield call(request, uri, {
      method: 'GET'
    });
    if(callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}

export function* taskcancel ({id,callback}) {
  try {
    const uri = m1+ '/bus/task/cancel/?id='+id;
    const data = yield call(request, uri, {
      method: 'PUT'
    });
    if(callback) callback(data);
  } catch (error) {
    console.log(error);
  }
}



export function* taskfinished ({id,callback}) {
  try {
    const uri = m1+ '/task/?id='+id;
    const data = yield call(request, uri, {
      method: 'GET'
    });
    if(callback) callback(data['Data']);
  } catch (error) {
    console.log(error);
  }
}





export function* watchTaskexe(){
  yield takeLatest('TASK_EXE',taskexe)
}
export function* watchTasktest(){
  yield takeLatest('TASK_TEST',tasktest)
}


export function* watchFetch () {
  yield takeLatest('TASK_FETCH', fetch);
}
export function* watchTaskdetails () {
  yield takeLatest('TASK_DETAILS', taskdetails);
}


export function* watchSearch () {
  yield takeLatest('TASK_SEARCH', search);
}

export function* watchCreateby () {
  yield takeLatest('TASK_CREATEBY', searchcreateby);
}

export function* watchDistrict () {
  yield takeLatest('TASK_DISTRICT', searchdistrict);
}


export function* watchCar () {
  yield takeLatest('TASK_CAR', searchcar);
}

export function* watchStatus () {
  yield takeLatest('TASK_STATUS', searchstatus);
}



export function* watchTaskadd () {
  yield takeLatest('TASK_ADD', taskadd);
}


export function* watchCartasklist () {
  yield takeLatest('TASKLIST_OFCAR', cartasklist);
}


export function* watchTaskbegin () {
  yield takeLatest('TASK_BEGIN', taskbegin);
}

export function* watchTaskcancel () {
  yield takeLatest('TASK_CANCEL', taskcancel);
}


export function* watchTaskfinished () {
  yield takeLatest('TASK_FINISHED', taskfinished);
}

export function* fetchTaskList_new(){
  yield takeLatest('NEW_TASK_LIST',newTaskList)
}

export function* watchNewTaskAdd(){
  yield takeLatest('NEW_ADD_TASK',newTaskAdd)
}


export default [
  watchFetch,
  watchTaskdetails,
  watchSearch,
  watchCreateby,
  watchDistrict,
  watchCar,
  watchTaskadd,
  watchStatus,
  watchCartasklist,
  watchTaskbegin,
  watchTaskcancel,
  watchTaskfinished,
  watchTaskexe,
  watchTasktest,
  fetchTaskList_new,
  watchNewTaskAdd
];
