import mqtt from "mqtt";
import config from '../utils/config.js';
import request from '../utils/request';
import { getUserinfo, getAuthority } from '../utils/authority'
import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { Utf8ArrayToStr } from '../utils/utils';
import { getCurHMS } from '../utils/utils'
const protobuf = require("protobufjs");
const jsonDescriptor = require("./msg.json");
const carStateJson = require("./stateMessage.json");
const root = protobuf.Root.fromJSON(jsonDescriptor);
const stateRoot = protobuf.Root.fromJSON(carStateJson);
const username = getUserinfo()
const token = getAuthority() ? getAuthority().split(" ")[1] : "";
const { mqttHost: host } = config
let protpfile = null; // 缓存proto文件

let mqttClient = null
let connectUrl;
let _mydispatch;

function carmqttconnect({ mydispatch, id }) {
    _mydispatch = mydispatch
    const connectConfig = {
        clientId: `udi-web-${username}-uuid`,
        clear: true,
        username,
        password: token,
        host
    }
    connectUrl = `/udi/backend/${id}`;
    try {
        const { host } = connectConfig
        mqttClient = mqtt.connect(host, connectConfig)

        mqttClient.on("connect", () => {
            console.log("mqtt 建立连接成功");
            mydispatch({ type: 'carwebsocket/success' });
            mqttClient.subscribe(connectUrl, (error, res) => {
                if (error) {
                    console.log("订阅主题错误", error);
                    return;
                }
                console.log("订阅主题", res);
            });
        });
        mqttClient.on("message", (topic, buf) => {
            const messageFile = root.lookupType("Web.Message");
            const { classId } = messageFile.decode(buf);
            console.log(classId);
            const dateKey = getCurHMS()
            switch (classId) {
                case 6:
                    const carMessage = root.lookupType("Web.StateReport");
                    const carStatus = carMessage.decode(buf);
                    // console.log(carStatus)
                    carStatus.payload.localTaskId = Utf8ArrayToStr(carStatus.payload.localTaskId);
                    carStatus.payload.platformTaskId = Utf8ArrayToStr(carStatus.payload.platformTaskId);
                    carStatus.carId = Utf8ArrayToStr(carStatus.carId);
                    // console.log(carStatus.payload)
                    mydispatch({ type: 'carwebsocket/carstatus', data: carStatus.payload });//车辆状态消息
                    if (carStatus.payload.longitude && carStatus.payload.latitude) {
                        mydispatch({ type: 'carwebsocket/realtimecargps', data: [carStatus.payload.longitude, carStatus.payload.latitude] });//车辆状态消息
                    }
                    //mydispatch({ type : 'carwebsocket/messages',data:{classid:6,time:dateKey,msg:'车辆正常状态消息'}});//车辆状态消息
                    break;
                case 9:
                    //调度任务开始通知startTime
                    const beginMessage = root.lookupType("Web.StartTask");
                    const beginStatus = beginMessage.decode(buf);//转化成object
                    console.log(beginStatus);
                    mydispatch({ type: 'carwebsocket/taskstart', data: beginStatus.payload });//调度任务开始通知;传给reducer的数据格式是obj
                    mydispatch({ type: 'carwebsocket/messages', data: { classid: 9, time: dateKey, msg: '任务开始通知' } });
                    break;
                case 13:
                    const plannedrouteMessage = root.lookupType("Web.PlannedRoute");
                    const pathplanStatus = plannedrouteMessage.decode(buf);//转化成object
                    console.log('路径规划通知');

                    console.log(pathplanStatus);
                    mydispatch({ type: 'carwebsocket/plannedWarning', data: 1 });//路径规划通知
                    mydispatch({ type: 'carwebsocket/messages', data: { classid: 13, time: dateKey, msg: '规划路径通知' } });
                    break;
                case 11:
                    /*  const endMessage = root.lookupType("Web.EndTask"); */
                    //const endBuf = new Uint8Array(message.Payload);
                    /* const endStatus = endMessage.decode(buf);//转化成object */
                    console.log('任务结束通知');
                    mydispatch({ type: 'carwebsocket/taskend', data: 1 });//任务结束通知endTime
                    mydispatch({ type: 'carwebsocket/messages', data: { classid: 11, time: dateKey, msg: '任务结束通知' } });
                    break;
                // case 10:
                //     mydispatch({ type : 'carwebsocket/returnmsg',data:message});//终止调度任务指令
                //     break;
                // case 8:
                //     mydispatch({ type : 'carwebsocket/returnmsg',data:message});//下发调度任务指令
                //     break;
                case 15:
                    const takeoverMessage = root.lookupType("Web.TakeOverReport");
                    //const takeoverBuf = new Uint8Array(message.Payload);
                    console.log(takeoverMessage)
                    // const takeOver = takeoverMessage.decode(buf);//转化成object
                    // console.log(takeOver);
                    // if(Utf8ArrayToStr(takeOver.payload.takeOverType)==0){mydispatch({ type : 'carwebsocket/messages',data:{classid:15,time:dateKey,msg:'车辆开始接管'}});}
                    // if(Utf8ArrayToStr(takeOver.payload.takeOverType)==1){mydispatch({ type : 'carwebsocket/messages',data:{classid:15,time:dateKey,msg:'车辆结束接管'}});}

                    //mydispatch({ type : 'carwebsocket/takeover',data:takeOver});//接管操作
                    break;
                case 7:
                    /* const hardwareWarnMessage = root.lookupType("Web.HardwareWarn");
                    const hardwareWarn = hardwareWarnMessage.decode(buf);//转化成object */
                    // console.log(hardwareWarn);
                    mydispatch({ type: 'carwebsocket/messages', data: { classid: 7, time: dateKey, msg: '硬件异常报告' } });
                    //mydispatch({ type : 'carwebsocket/hardwarewarn',data:message});//硬件行驶异常报告
                    break;
                case 8:
                    /*  const drivingWarnMessage = root.lookupType("Web.DrivingWarn");
                     const drivingWarn = drivingWarnMessage.decode(buf);//转化成object */
                    // console.log(drivingWarn);
                    mydispatch({ type: 'carwebsocket/messages', data: { classid: 8, time: dateKey, msg: '行驶异常告警' } });
                    //mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
                    break;


                case 20:
                    // console.log('任务结束通知')
                    const warnmsg = root.lookupType("Web.ErrWarn");
                    const warnmsgData = warnmsg.decode(buf);//转化成object
                    // console.log(warnmsgData)
                    // console.log('-------------------------')
                    const errcodeArr = []
                    warnmsgData.payload.nodes.forEach((item, key) => {
                        errcodeArr.push(item)
                    })
                    // console.log(errcodeArr)
                    // warnmsgData.payload.nodes.forEach((item,key)=>{
                    //     key === 0 ? data = item.code : data = data + '、' + item.code
                    // })
                    //mydispatch({ type : 'carwebsocket/messages',data:{classid:20,time:dateKey,msg:data}});

                    mydispatch({ type: 'carwebsocket/errorcode', data: { classid: 20, errcodeArr: errcodeArr } });
                    break;
                case 29:
                    const PresetPositionmsg = root.lookupType("Web.PresetPosition");
                    const PresetPositionmsgData = PresetPositionmsg.decode(buf);//转化成object

                    console.log('收到29消息=============================')
                    console.log(PresetPositionmsgData)

                    mydispatch({ type: 'carwebsocket/presetposition' });
                    break;
                case 30:
                    const Cruisemsg = root.lookupType("Web.Cruise");
                    const CruisemsgData = Cruisemsg.decode(buf);//转化成object

                    console.log('收到30消息=============================')
                    console.log(CruisemsgData)

                    mydispatch({ type: 'carwebsocket/cruisemsg' });
                    break;
                case 36:
                    //一键求助报警上报
                    const AlarmMenuReportMessage = root.lookupType("Web.AlarmMenuReport");
                    const AlarmMenuReport = AlarmMenuReportMessage.decode(buf);//转化成object
                    console.log(AlarmMenuReport);
                    console.log('--------------36---------------------')
                    mydispatch({ type: 'carwebsocket/messages', data: { classid: 35, time: dateKey, msg: '一键报警!' } });
                    mydispatch({ type: 'carwebsocket/blacklpncmd', data: AlarmMenuReport.payload });
                    //mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
                    break;

                // case 37:
                //     //黑名单车牌号报警上报
                //     const AlarmBlackLpnReportMessage = root.lookupType("Web.AlarmBlackLpnReport");
                //     const AlarmBlackLpnReport = AlarmBlackLpnReportMessage.decode(buf);//转化成object
                //     console.log(AlarmBlackLpnReport);
                //     console.log('--------------37---------------------')

                //     // mydispatch({ type : 'carwebsocket/blacklpncmd',data:{classid:37,data:BlackLpnCmd}});
                //     //mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
                //     break;

                case 41:
                    //抓拍图片通知前端  车牌号为1， 人脸为2，抓拍为3
                    const CaptureNotifyMessage = root.lookupType("Web.CaptureNotify");
                    const CaptureNotify = CaptureNotifyMessage.decode(buf);//转化成object
                    console.log('--------------41---------------------')

                    console.log(CaptureNotify);
                    CaptureNotify.payload.name = Utf8ArrayToStr(CaptureNotify.payload.name)
                    CaptureNotify.payload.object = Utf8ArrayToStr(CaptureNotify.payload.object)
                    mydispatch({ type: 'carwebsocket/capturenotify', payload: CaptureNotify.payload })


                    // mydispatch({ type : 'carwebsocket/blacklpncmd',data:{classid:37,data:BlackLpnCmd}});
                    //mydispatch({ type : 'carwebsocket/drivingwarn',data:message});//行驶异常告警
                    break;
                case 45:
                    // 车辆状态数据接收
                    const stateMessage = stateRoot.lookupType("Net2.CarData");
                    const { payload } = stateMessage.toObject(stateMessage.decode(buf))
                    console.log(payload,'852147');
                    const { errorCodes,
                        drivingStatus: {
                            gearStatus,
                            backLeftWheelSpeedFeedback,
                            backRightWheelSpeedFeedback,
                            speedFeedback,
                            brakePressureFeedback,
                            steeringDegreeFeedback,
                            motorRpmFeedback
                        },
                        chassisStatus: {
                            odometer,
                            tripmeter,
                            leftFrontTirePressure,
                            rightFrontTirePressure,
                            leftRearTirePressure,
                            rightRearTirePressure,
                            leftFrontTireTemperature,
                            rightFrontTireTemperature,
                            leftRearTireTemperature,
                            rightRearTireTemperature,
                        },
                        powerSystemStatus: {
                            batterySoc,
                            chargeStatus,
                            chargerOutputVoltage,
                            chargerOutputCurrent,
                        },
                        drivingCmd: {
                            chassisControlModeCode,
                            brakePressureCmd,
                            steeringDegreeCmd,
                            motorRpmCmd,
                        }
                    } = payload
                    let setErrorCodes = {}
                    let newCodes = []
                    console.log(errorCodes,'2323636');
                    errorCodes.forEach(item => {
                        item.key = Utf8ArrayToStr(item.errorCode)
                        item.errorCode = Utf8ArrayToStr(item.errorCode)
                        setErrorCodes[item.key] = item
                    })
                    for (const key in setErrorCodes) {
                        newCodes.push(setErrorCodes[key])
                    }


                    const speedChart = {
                        backLeftWheelSpeedFeedback: formatData(backLeftWheelSpeedFeedback),
                        backRightWheelSpeedFeedback: formatData(backRightWheelSpeedFeedback),
                        speedFeedback: formatData(speedFeedback)
                    }
                    const mileageChart = {
                        odometer: formatData(odometer),
                        tripmeter: formatData(tripmeter)
                    }

                    const batteryChart = {
                        batterySoc: formatData(batterySoc),
                        chargeStatus: formatData(chargeStatus),
                        chargerOutputVoltage: formatData(chargerOutputVoltage),
                        chargerOutputCurrent: formatData(chargerOutputCurrent)
                    }

                    const carData = {
                        chassisControlModeCode: formatData(chassisControlModeCode),
                        gearStatus: formatData(gearStatus),
                        leftFrontTirePressure: formatData(leftFrontTirePressure),
                        rightFrontTirePressure: formatData(rightFrontTirePressure),
                        leftRearTirePressure: formatData(leftRearTirePressure),
                        rightRearTirePressure: formatData(rightRearTirePressure),
                        leftFrontTireTemperature: formatData(leftFrontTireTemperature),
                        rightFrontTireTemperature: formatData(rightFrontTireTemperature),
                        leftRearTireTemperature: formatData(leftRearTireTemperature),
                        rightRearTireTemperature: formatData(rightRearTireTemperature),
                    }

                    const hardWareMesage = {
                        brakePressureCmd: formatData(brakePressureCmd),
                        steeringDegreeCmd: formatData(steeringDegreeCmd),
                        motorRpmCmd: formatData(motorRpmCmd),
                        brakePressureFeedback: formatData(brakePressureFeedback),
                        steeringDegreeFeedback: formatData(steeringDegreeFeedback),
                        motorRpmFeedback: formatData(motorRpmFeedback),
                    }


                    // 收到消息的时间戳
                    const resiveTime = new Date().getTime();
                    // console.log(resiveTime);
                    const newState = {
                        resiveTime,
                        errorCodes: newCodes,
                        chartData: {
                            speedChart,
                            mileageChart,
                            batteryChart
                        },
                        carData,
                        hardWareMesage
                    }
                    mydispatch({ type: "carwebsocket/updateState", newState })

                    break

                default:
                    break;
            }

            // console.log('收到', buf, `来自${topic}`);
        })

        mqttClient.on("error", (error) => {
            console.log("MQTT 建立连接失败", error);
            mydispatch({ type: 'carwebsocket/fall' });
        });
    } catch (e) {
        console.error('mqtt连接异常', e);
    }

}

function carmqttclose() {
    try {
        mqttClient.end();
        mqttClient = null;
        console.log("断开成功");
        _mydispatch({ type: "carwebsocket/clearState" })
        _mydispatch({ type: 'carwebsocket/messages', data: { classid: 0, time: '车辆断开连接通知', msg: '小车websocket断开' } });
        _mydispatch({ type: 'carwebsocket/fall' });
    } catch (e) {
        console.log("断开异常", e);
    }
}


//获取这个任务的小车实时已经走过的路径
export function* realtimegps({ carId, PlatformTaskId, callback }) {
    try {
        const uri = '/api/m1/cargps/' + carId + '/?PlatformTaskId=' + PlatformTaskId;
        const data = yield call(request, uri, {
            method: 'GET'
        });
        if (data.GPS != null) {

            //格式转化;统一格式为[[],[],[]]的二维数组
            var gpsCon = [];
            var num = 0;
            for (var j in data['GPS']) {
                gpsCon[num] = [data['GPS'][j].Longitude, data['GPS'][j].Latitude]
                num++;
            }

            if (callback) { callback(gpsCon) };
            yield put({
                type: 'carwebsocket/cargps',
                payload: gpsCon
            });
        }
    } catch (error) {
        console.log(error);
    }
}


function* sendmsg(msg) {
    console.log(msg)
    try {
        yield fork(decodeencodewithproto, msg);
    } catch (err) {
        console.log(err);
    }
}

function formatData(val) {
    if (val.isValid) {
        if (val.data) {
            return val.data
        } else {
            return 0
        }
    }
    else {
        return null
    }
}

function* decodeencodewithproto(msg) {
    let restroot;
    if (protpfile == null) {
        // 缓存proto 对象
        restroot = yield call(protobuffun);
        protpfile = restroot;
    } else {
        restroot = protpfile;
    }

    console.log(msg)
    switch (msg.msgtype) {
        case 'cloundmsg':
            var ManualControl = restroot.lookupType("Web.ManualControl");
            const tempdata = {
                classId: 28,
                payload: {
                    ...msg.data
                }
            }
            console.log(tempdata)
            var errMsg = ManualControl.verify(tempdata);
            if (errMsg)
                throw Error(errMsg);
            var message = ManualControl.create(tempdata);
            console.log(message)
            // console.log( ManualControl.encode(message))
            var buffer = ManualControl.encode(message).finish();
            // console.log(buffer)
            // console.log(Utf8ArrayToStr(buffer))
            console.log(buffer)
            mqttClient.publish(connectUrl, buffer, 2, e => {
                if (e) {
                    console.log("发送失败", e);
                }
            });
            // console.log(msg)
            break;
        // case 'continueCar':
        //     break;
        // case 'whistle':
        //     break;
        default:

    }

    // var Area = restroot.lookupType("Web.Area");
    // var payload = { data: msg };
    // var errMsg = Area.verify(payload);
    // if (errMsg)
    //     throw Error(errMsg);
    // var message = Area.create(payload);
    // var buffer = Area.encode(message).finish();
    // ws.send(buffer);

}


function protobuffun() {
    return new Promise(resolve => {
        var jsonDescriptor = require("./msg.json");
        var root = protobuf.Root.fromJSON(jsonDescriptor);
        resolve(root);
    })
}

export function* watchCarMqtt() {
    yield takeLatest('CAR_MQTT_CONNECT', carmqttconnect);
}


export function* closeCarMqtt() {
    yield takeLatest('CAR_MQTT_CLOSE', carmqttclose);
}


export function* watchCarsendmsg() {
    yield takeLatest('CAR_MQTT_SEND', sendmsg);
}

export function* watchRealtimegps() {
    yield takeLatest('REALTIME_GPS', realtimegps);
}

export default [watchCarMqtt, closeCarMqtt, watchRealtimegps, watchCarsendmsg]