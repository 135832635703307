var BASE_DOMAIN = 'udp.unity-drive.net'
// var BASE_DOMAIN = 'uditest.unity-drive.net'
BASE_DOMAIN = window.location.host === BASE_DOMAIN ? BASE_DOMAIN : 'uditest.unity-drive.net';
var BASE_URL = `${BASE_DOMAIN}`

const config = {
    BASE_ORIGIN_URL:`https://${BASE_DOMAIN}`,
    websocket:`wss://${BASE_URL}/api/m1/web`,
    aliyunConfig:{
        host:BASE_URL,
        appHost:`${BASE_URL}`,
        appServerUrl:`${BASE_URL}/oss/ststoken`,
        bucket:'udi-map',
        region:'oss-cn-shenzhen',
    },
    m1:'/api/m1',
    captchaStr:`https://${BASE_URL}/api/v1/pub/login/captcha?`,
    aliImg:'https://udi-rtmp-video.oss-cn-shenzhen.aliyuncs.com/',
    amapkey:'70c5b4389cc8554a807c541c7807ec7a',
    mqttHost:`wss://${BASE_DOMAIN}:443/mqtt`,
}
export default config;
