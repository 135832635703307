export const namespace = 'areawebsocket';
const initwsState ={
    status:'未连接',
    isSuccess:false,
    ws:null,
    msg:"",
    errcodeMsg:{}
}

export default function areawebsocket(state=initwsState,action) {
    switch (action.type){
        case `${namespace}/success`:
            return{
                ...state,
                status:"连接成功",
                isSuccess:true
            }
        case `${namespace}/fall`:
            return{
                ...state,
                status:"未连接",
                msg:"",
                isSuccess:false
            }
        case `${namespace}/returnmsg`:
            return{
                ...state,
                msg:action.data
            }
        case `${namespace}/carstatus`:
            return{
                ...state,
                msg:action.data
            }
        case `${namespace}/errorcode`:
            const dataObj = state.errcodeMsg
            dataObj[`${action.data.carid}`]?dataObj[`${action.data.carid}`].push(...action.data.errcodeArr):dataObj[`${action.data.carid}`]=action.data.errcodeArr
            Object.keys(dataObj).forEach((index,i)=>{
                dataObj[index] = [...new Set(dataObj[index])]
            })
            return {
                ...state,
                errcodeMsg:{...dataObj}
            }
        case `${namespace}/clearerrorcode`:
            return{
                ...state,
                errcodeMsg:{}
            }
        default:
            return state;
    }
}