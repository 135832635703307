export const namespace = 'login';

export default function login (state = {}, action) {
  switch (action.type) {
    case `${namespace}/request`:
      return {
        ...state,
        status: 'pending',
        pending: true
      };
    case `${namespace}/success`:
      return {
        ...state,
        status: 'success',
        pending: false
      };
    case `${namespace}/failed`:
      return {
        ...state,
        status: 'error',
        pending: false,
        message: action.payload
      }
    case `${namespace}/logout`:
      return {
        ...state,
        status: 'success',
        pending: false
      }
    default:
      return state;
  }
}
