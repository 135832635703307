export const namespace = 'manage';

export default function manage (state = {}, action) {
  switch (action.type) {
    case `${namespace}/userlist`:
      console.log(action)
      return {
        ...state,
        userlist:action.payload
      };
    
    case `${namespace}/rolelist`:
      return{
        ...state,
        rolelist:action.payload
      }
    case `${namespace}/menuslist`:
      return{
        ...state,
        menuslist:action.payload
      }
    

      

    default:
      return state;
  }
}
